import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@practica/auth/auth.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { ThemeService } from "../../../theme.service";

@Component({
  selector: "practica-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  private _scrollOffset = 0;
  FacultyName: string;
  ShortName: string;
  FacultyLogo: string;
  AccountPath: string;
  LoginPath: string;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private authService: AuthService,
    private readonly _themeService: ThemeService,
    private readonly _route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.setFacultyName();
    this.setFacultyShortName();
    this.setFacultyLogo();
    this.setUserPath();
  }

  public get hasShadow(): boolean {
    return this.router.url !== "/" || this._scrollOffset > 16;
  }

  ngOnInit() {}

  @HostListener("window:scroll", [])
  public onScroll() {
    if (isPlatformBrowser(this.platformId)) {
      this._scrollOffset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
    }
  }

  showButton(): boolean {
    return Boolean(this.authService.getToken());
  }

  hideButton(): boolean {
    return !this.authService.getToken();
  }

  handleLogoutButtonClick(): void {
    this.authService.logout(this._route, true);
  }

  setFacultyName(): void {
    this.FacultyName = this._themeService.returnFacultyName(this._route);
  }

  setFacultyShortName(): void {
    this.ShortName = this._themeService.returnShortName(this._route);
  }

  setFacultyLogo(): void {
    this.FacultyLogo = this._themeService.getFacultyLogoPath(this._route);
  }

  setUserPath(): void {
    this.AccountPath =
      "/" + this._themeService.returnShortName(this._route) + "/user/profile";
    this.LoginPath =
      "/" + this._themeService.returnShortName(this._route) + "/user/login";
  }
}
