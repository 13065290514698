import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, OnInit} from '@angular/core';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {Faq} from '@practica/graphql/graphql.types';

@Component({
    selector: 'practica-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
    loading: boolean;
    faqs: Faq[];
    availableColors: string[] = ['#DAFBEB', '#FBE2DA', '#EEEEEE', '#DAFBEB', '#DAF5FB']
    assignedColors: string[];

    constructor(private graphql: GraphQLService) {
    }

    ngOnInit() {
        this.graphql.faqs().valueChanges.subscribe((result) => {
            this.loading = result.loading;
            this.faqs = result.data.faq.edges.map(edge => edge.node);
            this.assignedColors = this.assignColors(this.faqs.length);
        });
    }

    assignColors(numberOfAssignedColors: number) : string[] {
        let assignColor = () => this.availableColors[Math.floor(Math.random() * this.availableColors.length)];
        let assignedColors = [];

        for(let i = 0; i < numberOfAssignedColors; i++)
            assignedColors.push(assignColor());

        return assignedColors;
    }
}
