import {
  isPlatformBrowser,
  isPlatformServer,
  registerLocaleData,
} from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import localeRoExtra from "@angular/common/locales/extra/ro";
import localeRo from "@angular/common/locales/ro";
import {
  ErrorHandler,
  Inject,
  LOCALE_ID,
  NgModule,
  Optional,
  PLATFORM_ID,
} from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { REQUEST } from "../express.tokens";

import { Angulartics2Module } from "angulartics2";
import { Request } from "express";
import player from "lottie-web";
import { LottieModule } from "ngx-lottie";
import { environment } from "../environments/environment";
import { RavenWrapper } from "./RavenWrapper";
import { AppComponent } from "./app.component";
import { PracticaModule } from "./practica/practica.module";
import { ScrollService } from "./scroll.service";
import { ThemeService } from "./theme.service";
import { TimeoutInterceptor } from "./timeout.interceptor";

registerLocaleData(localeRo, "ro-RO", localeRoExtra);

export function playerFactory() {
  return player;
}

export class RavenErrorHandler implements ErrorHandler {
  constructor(private raven: RavenWrapper) {}

  handleError(err: any): void {
    this.raven.captureException(err);
    // rethrow error to be displayed in console
    throw err;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "practica-frontend" }),
    RouterModule.forRoot([
      {
        path: "",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./practica/practica-routing.module").then(
                (m) => m.PracticaRoutingModule
              ),
          },
        ],
      },
    ]),
    PracticaModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearQueryParams: true,
      },
    }),
    [LottieModule.forRoot({ player: playerFactory })],
  ],
  providers: [
    Title,
    ScrollService,
    RavenWrapper,
    ThemeService,
    { provide: LOCALE_ID, useValue: "ro-RO" },
    {
      provide: ErrorHandler,
      useFactory: (raven) => new RavenErrorHandler(raven),
      deps: [RavenWrapper],
    }, // wtf??
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(PLATFORM_ID) private platformId: Object,
    private raven: RavenWrapper
  ) {
    let env: string;
    if (isPlatformServer(this.platformId) && this.request) {
      if (this.request.hostname) {
        env = this.request.hostname;
      }
    } else if (
      isPlatformBrowser(this.platformId) &&
      typeof window !== "undefined"
    ) {
      if (window.location && window.location.hostname) {
        env = window.location.hostname;
      }
    }

    raven.config("https://339e7bc952944fe98bda83f4815a843e@sentry.io/302362", {
      release: environment.commitHash,
      environment: env,
    });
  }
}
