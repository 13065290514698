<section class="overflow-hidden row px-md-0 px-3">
    <h3 class="section-title py-5">Ce ai de făcut?</h3>
    <div class="pb-4" #cardsContainer>
        <div class="d-flex justify-content-xl-center">
            <practica-card [cardAnimation]="card.id" [title]="card.title" [description]="card.description"
                           *ngFor="let card of cards">

            </practica-card>
        </div>
    </div>

</section>
