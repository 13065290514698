<section *ngIf="companies?.length > 0">
    <h3 class="section-title row py-5 px-md-0 px-3">Companii partenere</h3>
    <main class="row">
        <div class="col-lg-3 col-md-4 col-sm-2 col-6 scale" *ngFor="let company of companies">
            <a [routerLink]="['./company', company.slug]">
                <practica-lazy-image
                        style="cursor: pointer"
                        alt="{{company.name}} logo" [images]="company.logo"
                        sizes="(min-width: 1200px) 18vw, (min-width: 992px) 22vw, (min-width: 768px) 30vw, (min-width: 576px) 45vw, 90vw">
                </practica-lazy-image>
            </a>
        </div>
    </main>
</section>
