<div class="container d-flex align-items-center justify-content-center h-75 w-100">
    <div class="d-flex align-items-md-center justify-content-center w-100">
        <form class="login-form p-3 p-sm-5" [action]="action" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <h2 class="primary">
                Bine ai (re)venit!
            </h2>
            <h2 class="secondary pb-4">
                Intră în contul tău.
            </h2>
            <fieldset [disabled]="disabled" class="d-flex flex-column justify-content-center">
                <div class="d-flex flex-column justify-content-center">
                    <label class="d-flex flex-column">
                        ADRESA E-MAIL
                        <input class="my-2 " type="email" aria-label="email" placeholder="prenume.nume@student.upt.ro"
                               formControlName="email">
                    </label>
                    <label class="d-flex flex-column my-4">
                        PAROLĂ
                        <input class="my-2" type="password" aria-label="password" placeholder="parolă"
                               formControlName="password">
                    </label>
                </div>

                <div class="d-flex justify-content-end pb-4">
                    <a href="https://student.upt.ro/docs/Instructiuni%20resetare%20parola%20cont-UPT.pdf">
                        Ai uitat parola?
                    </a>
                </div>

                <div class="d-flex w-100 justify-content-center">
                    <button type="submit" [disabled]="!loginForm.valid" class="practica-btn d-flex">
                        Intră în cont
                        <i class="gg-enter"></i>
                    </button>
                </div>

                <div *ngIf="showErrors" class="alert alert-danger mt-4">
                    <div *ngIf="email.errors?.email || email.errors?.required">
                        Adresă e-mail invalidă
                    </div>
                    <div *ngIf="password.errors?.required">
                        Parola nu poate fi vidă
                    </div>
                    <div *ngIf="networkError">
                        {{ networkError }}
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>
