import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AuthService} from '@practica/auth/auth.service';
import lottieWeb from 'lottie-web';
import {FileUploader} from 'ng2-file-upload';
import {FileLikeObject} from 'ng2-file-upload/file-upload/file-like-object.class';

@Component({
  selector: 'practica-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss']
})
export class DocumentUploaderComponent implements OnInit, AfterViewInit {

  @Input() public uploadUrl: string;
  @Input() public itemAlias: string;
  @Input() public file?: string;
  @Input() public fileDescriptionName: string;
  @Input() public sectionTitle: string;
  @Input() public status?: string;
  @Input() public comment?: string;
  @Input() public templateUrl?: string;
  uploader: FileUploader;
  dropZoneFileOver = false;
  form: UntypedFormControl = null;
  constructor(private auth: AuthService, private loadingBar: LoadingBarService) { }

  ngOnInit(): void {
    this.uploader = new FileUploader({
      url: this.uploadUrl,
      itemAlias: this.itemAlias,
      autoUpload: true,
      removeAfterUpload: true,
      queueLimit: 1,
      allowedFileType: ["pdf"],
      maxFileSize: 10 * 1024 * 1024,
      authTokenHeader: "Authorization",
      authToken: this.auth.getToken()
          ? `Bearer ${this.auth.getToken().key}`
          : null,
    });
    this.form = new UntypedFormControl(this.file || null);

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (status === 201 && headers.hasOwnProperty("location")) {
        this.form.setValue(headers["location"]);
        this.form.setErrors(null);
      } else {
        this.form.setErrors({ unknown: true });
      }
    };

    this.uploader.onErrorItem = () => {
      this.form.setErrors({ upload: true });
    };

    this.uploader.onBeforeUploadItem = () => this.loadingBar.start(5);
    this.uploader.onProgressAll = (progess) =>
        this.loadingBar.set(Math.max(5, progess));
    this.uploader.onCompleteAll = () => this.loadingBar.complete();

    this.uploader.onAfterAddingFile = () => {
      this.form.setErrors(null);
    };

    this.uploader.onWhenAddingFileFailed = (
        item: FileLikeObject,
        filter: any
    ) => {
      if (filter && filter.name === "fileSize") {
        this.form.setErrors({ fileSize: true });
      } else {
        this.form.setErrors({ fileType: true });
      }
    };
  }

  ngAfterViewInit(): void {
    lottieWeb.loadAnimation({
      container: document.getElementById("UploadAnimation" + '-' + this.itemAlias),
      path: "./assets/file-upload.json",
      renderer: "svg",
      loop: true,
      autoplay: true,
      name: "Anim",
    });
  }

  fileOver(e: boolean) {
    this.dropZoneFileOver = e;
  }

  get filename() {
    const fileurl = decodeURI(this.form.value);
    return fileurl.substring(fileurl.lastIndexOf("/") + 1);
  }

  get translatedStatus(): string {
    if (this.status == 'PRE') return 'În prelucrare';
    if (this.status == 'FAC') return 'Acceptat facultate';
    if (this.status == 'UNI') return 'Acceptat universitate';
    if (this.status == 'ACC') return 'Acceptat';
    if (this.status == 'REC') return 'Acceptat rectorat';
    if (this.status == 'MOD') return 'Necesită modificare';

    return this.status;
  }

}
