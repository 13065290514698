import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'practica-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() open: boolean;
  constructor() {}
  @Output() closeMeEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();
  ngOnInit(): void {
  }

  closeMe() {
    this.open = false;
    window.removeEventListener('click', () => {})
    this.closeMeEvent.emit();
  }

  confirm() {
    this.open = false;
    window.removeEventListener('click', () => {})
    this.confirmEvent.emit();
  }
}
