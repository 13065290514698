<div class="container my-5">
    <div class="row mb-5" *ngIf="showIncompleteProfileAlert">
        <div class="col-12">
            <practica-alert class="row incomplete-profile-alert" (close)="closeIncompleteProfileAlert()">
                <h3 class="section-title">Ești cu un pas mai aproape de a-ți găsi practică.</h3>
                <p>Încarcă CV-ul și aplică la companii.</p>
            </practica-alert>
        </div>
    </div>

    <div class="row mb-5" *ngIf="showCompleteProfileAlert">
        <div class="col-12">
            <practica-alert class="row complete-profile-alert">
                <h3 class="section-title">Profilul tău este pregătit!</h3>
                <p>Acum poți aplica la companii.</p>
                <div class="mt-4 d-flex justify-content-end">
                    <a [routerLink]="goToCompaniesUrl" class="practica-btn apply-btn">Către companii</a>
                </div>
            </practica-alert>
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-12">
            <practica-alert class="row documents-alert">
                <h3 class="section-title">Încarcă aici documentele pentru practică!</h3>
                <div class="mt-4 d-flex justify-content-end">
                    <a [routerLink]="goToDocumentsUrl" class="practica-btn apply-btn">Către pagina documentelor</a>
                </div>
            </practica-alert>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-7 no-padding">
            <h3 class="section-title pb-3">Salut, {{user.firstName}}!</h3>
            <section class="practica-colored-wrapper d-flex flex-column w-100">
                <h4 class="pb-3">Date de contact</h4>
                <form [formGroup]="profileForm" (ngSubmit)="saveProfile(profileForm)">
                    <fieldset class="d-flex flex-column w-100">
                        <label>
                            <i class="gg-mail"></i>
                            <div>
                                <input type="email" [value]="user.email" disabled aria-label="email"
                                       placeholder="adresă e-mail">
                            </div>
                        </label>
                        <label>
                            <i class="gg-smartphone"></i>
                            <input type="tel" aria-label="phone number" placeholder="număr de telefon"
                                   formControlName="phone">
                        </label>
                        <label>
                            <i class="fab fa-linkedin"></i>
                            <input type="url" aria-label="linkedin" placeholder="link profil Linkedin"
                                   formControlName="linkedin">
                        </label>

                        <div class="d-flex justify-content-end pt-3">
                            <button type="submit" class="practica-btn" [disabled]="!profileForm.valid">Salvează
                                modificările
                            </button>
                        </div>
                    </fieldset>
                </form>
            </section>

            <section class="practica-colored-wrapper d-flex flex-column w-100 mt-3">
                <h4 class="pb-3">Cu ce te lauzi</h4>
                <form id="companies-form" [formGroup]="companiesForm" (ngSubmit)="saveProfile(companiesForm)">
                    <fieldset>
                        <label>
                            <i class="fab fa-github"></i>
                            <input type="url" aria-label="github" placeholder="link profil GitHub"
                                   formControlName="github">
                        </label>

                        <div ng2FileDrop
                             (fileOver)="fileOver($event)"
                             [uploader]="uploader"
                             class="file-drop-zone">

                            <input type="file" id="file-cv"
                                   ng2FileSelect
                                   [uploader]="uploader"
                                   accept=".pdf,.doc,.docx,.odt,.txt"
                                   [disabled]="uploader.isUploading || companiesForm.disabled"/>
                            <label for="file-cv" [class.file-over]="dropZoneFileOver" tabindex="-1">
                                <div *ngIf="formcv.value; else nocv" target="_blank" rel="noopener">
                                   <a class="filename" [href]="formcv.value"> <span>{{ cvname }}</span></a>
                                    <span class="uploader-text">Modifică CV-ul</span>
                                    <span class="uploader-text">(.pdf, .doc, .docx, .odt, .txt), max. 10 MB.</span>
                                </div>
                                <ng-template #nocv>
                                    <span class="filename"><div id="UploadAnimation" class="spacinganim"></div></span>
                                    <span class="uploader-text">Încarcă CV-ul tău aici*</span>
                                    <span class="uploader-text">(.pdf, .doc, .docx, .odt, .txt), max. 10 MB.</span>
                                </ng-template>

                                <ng-container *ngIf="formcv.errors; else nocverror">
                                    <span *ngIf="formcv.errors?.upload || formcv.errors?.unknown" class="error">Eroare la încărcarea fișierului pe server</span>
                                    <span *ngIf="formcv.errors?.fileType" class="error">Fișier invalid</span>
                                    <span *ngIf="formcv.errors?.fileSize" class="error">Fișierul depășește 10 MB</span>
                                </ng-container>
                                <ng-template #nocverror>
                                    <span class="uploader-text">Fă click sau aruncă un fișier aici.</span>
                                </ng-template>
                            </label>
                        </div>

                        <div class="d-flex justify-content-end pt-3">
                            <button type="submit" class="practica-btn" [disabled]="!companiesForm.valid">Salvează
                                modificările
                            </button>
                        </div>
                    </fieldset>
                </form>
            </section>
        </div>
        <div class="col-12 col-md-5 mt-3 mt-md-0 no-padding-right">
            <section class="practica-colored-wrapper green">
                <h3 class="pb-3">Despre tine</h3>
                <p>Nume: {{user.firstName + " " + user.lastName}}</p>
                <p>Număr matricol: {{user.reg}}</p>
                <p>Anul: {{profile.studyYear}}</p>
                <p>Profil: {{profile.facultyProfile}}</p>
                <p>Specializare: {{profile.specialization}}</p>
            </section>
            <section class="practica-colored-wrapper blue mt-3">
                <h3 class="pb-3">Informații și documente</h3>
                <a href="https://ac.upt.ro/practica-licenta/">
                    <div class="d-flex align-items-center justify-content-between">
                        <span>Documente utile</span>
                        <i class="gg-globe-alt"></i>
                    </div>

                </a>
                <a [routerLink]="'/faq'">
                    <div class="d-flex align-items-center justify-content-between">
                        <span>Întrebări frecvente</span>
                        <i class="gg-comment"></i>
                    </div>
                </a>
            </section>
        </div>
    </div>
</div>
