import { Component, OnInit } from '@angular/core';
import {ThemeService} from "../../../theme.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'practica-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  FacultyLogo: string;
  constructor(
      private readonly _themeService: ThemeService,
      private readonly _route: ActivatedRoute
  ) {
    this.setFacultyLogo();
  }

  ngOnInit(): void {
  }
  setFacultyLogo(): void {
    this.FacultyLogo = this._themeService.getFacultyLogoPath(this._route);
  }

}
