import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {CompanyInfo} from '@practica/graphql/graphql.types';

@Component({
    selector: 'practica-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
    loading: boolean;
    companies: CompanyInfo[];

    constructor(private graphql: GraphQLService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.graphql.companies(false, this.route.snapshot.paramMap.get('name')).valueChanges.subscribe((result) => {
            this.loading = result.loading;
            this.companies = result.data.companies.edges.map(edge => edge.node);
        });
    }
}
