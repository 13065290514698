<footer class="container-fluid">
    <div class="container d-flex justify-content-between align-items-center py-5">
        <div>
            <img src="{{FacultyLogo}}" alt="logo" *ngIf="FacultyLogo"/>
            <practica-upt-logo *ngIf="!FacultyLogo"></practica-upt-logo>
        </div>
        <div>
            <h3>CONTACT</h3>
            <p>Bulevardul Vasile Pârvan, Nr. 2, 300223, Timișoara, România</p>
            <p>practica&#64;upt.ro</p>
            <p>+40 256 403 211</p>
        </div>
    </div>
</footer>
