import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../../../theme.service';

@Component({
  selector: 'practica-faculty-selection',
  templateUrl: './faculty-selection.component.html',
  styleUrls: ['./faculty-selection.component.scss']
})
export class FacultySelectionComponent implements OnInit {
  public faculties;
  constructor(private themeService: ThemeService) {
    this.faculties = themeService.getFaculties();
  }

  ngOnInit(): void {
  }

}
