import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'practica-upt-short-logo',
  templateUrl: './upt-short-logo.component.html',
  styleUrls: ['./upt-short-logo.component.scss']
})
export class UptShortLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
