<ng-container *ngIf="open">
    <div class="modal-container" (click)="closeMe()"></div>
    <div class="content-container">
        <div class="content">
            <div class="close align-self-end" (click)="closeMe()">&times;</div>
            <ng-content></ng-content>
        </div>
    </div>
</ng-container>

