<div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="alert alert-primary p-4 text-center" role="alert">
                Există acord între companie și UPT?
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="d-flex align-items-center accord-buttons-wrapper pt-3">
                <i class="gg-arrow-right"></i>
                <div class="d-flex justify-content-center w-100">
                <div class="margin-right">
                    <button type="submit" class="practica-btn"
                            [ngClass]="hasAccord ? 'selected' : ''"
                            (click)="onAccordOptionSelect(true)"
                            [disabled]="hasAccord">
                        Există acord</button>
                </div>
                <button type="submit" class="practica-btn"
                        [ngClass]="hasAccord === false ? 'selected' : ''"
                        (click)="onAccordOptionSelect(false)"
                        [disabled]="hasAccord === false">
                    NU există acord</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" [ngClass]="hasAccord ? '' : 'd-none'">
        <div class="d-flex justify-content-center my-5 w-100">
            <i class="gg-arrow-down"></i>
        </div>
        <div class="col-12 col-md-6">
            <div class="d-flex align-items-center">
                <i class="gg-search"></i>
                <input type="search" class="search-box" placeholder="Caută numele companiei"
                       (input)="onAccordSearchChange($event.target.value)">
            </div>
            <div class="accord-list-container" #accordsContainer>
                <div class="accord-list-item" [ngClass]="selectedAccord && accord.id == selectedAccord.id ? 'accord-item-selected' : ''"
                     *ngFor="let accord of accordsFiltered" (click)="onAccordSelected(accord)">
                    {{accord.name}}
                    </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="alert alert-primary p-4 text-center" role="alert">
                Selectează numele companiei din listă
                <p>(Dacă nu găsești compania în listă, înseamnă că nu există acord)</p>
            </div>
        </div>

        <div class="row d-flex justify-content-center mt-5" *ngIf="showDocumentsSection">
            <practica-document-uploader
                    [uploadUrl]="convention_upload_url"
                    fileDescriptionName="convenția"
                    itemAlias="convention"
                    [sectionTitle]="'Încarcă convenția pentru ' + selectedAccord.name"
                    [file]="convention?.convention"
                    [status]="convention?.status"
                    [comment]="convention?.comment"
                    templateUrl="https://ac.upt.ro/wp-content/uploads/2021/05/030-Conventie-ro.pdf"
                    class="col-12 col-md-6">
            </practica-document-uploader>
            <practica-document-uploader
                    [uploadUrl]="atestat_upload_url"
                    fileDescriptionName="atestat-ul"
                    itemAlias="atestat"
                    sectionTitle="Încarcă atestatul"
                    [file]="atestat?.atestat"
                    [status]="atestat?.status"
                    [comment]="atestat?.comment"
                    templateUrl="https://ac.upt.ro/wp-content/uploads/2021/05/080-Atestat-de-practica.doc"
                    class="col-12 col-md-6">
            </practica-document-uploader>
            <practica-document-uploader
                    [uploadUrl]="caiet_de_practica_upload_url"
                    fileDescriptionName="caiet-ul de practică"
                    itemAlias="caiet_de_practica"
                    sectionTitle="Încarcă caiet-ul de practică"
                    [file]="caietDePractica?.caietDePractica"
                    [status]="caietDePractica?.status"
                    [comment]="caietDePractica?.comment"
                    templateUrl="https://ac.upt.ro/wp-content/uploads/2021/07/070-Caiet-de-practica.docx"
                    class="col-12 col-md-6 mt-5">
            </practica-document-uploader>
        </div>
    </div>
    <div class="row" *ngIf="hasAccord === false">
        <div class="d-flex justify-content-center my-5 w-100">
            <i class="gg-arrow-down"></i>
        </div>
        <div class="col-12">
            <div class="alert alert-danger p-4" role="alert">
                <p><b>Ce urmează să faci?</b></p>
                <p class="mt-3">1. Va trebui să duci fizic la semnat cele 2 documente (acord și protocol spre companie.</p>
                <p class="mt-1">2. Documentele semnate trebuie să ajungă la oficiul de practică.</p>
                <p class="mt-1">3. Revino în aplicație după aproximativ 5 zile pentru a continua procesul, firma având acord cu UPT.</p>
            </div>
        </div>
    </div>
</div>
