import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@practica/auth/auth.service";

@Injectable()
export class AuthGuard  {
  constructor(
    public auth: AuthService,
    public activatedRoute: ActivatedRoute
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(this.activatedRoute);
    console.log(route)
    const loggedIn = Boolean(this.auth.getToken());
    const requireLoggedIn = !route.data.notAuthGuard;
    if (loggedIn !== requireLoggedIn) {
      this.auth.navigate(this.activatedRoute).catch();
    }
    return loggedIn === requireLoggedIn;
  }
}
