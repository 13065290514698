<section class="practica-colored-wrapper d-flex flex-column w-100 mt-3">
    <h4 class="pb-3">{{sectionTitle}}</h4>
    <p *ngIf="templateUrl"><b>Template: </b><a [href]="templateUrl">Descarcă template-ul de aici</a></p>
    <p *ngIf="status"><b>Status:</b> {{translatedStatus}}</p>
    <div *ngIf="comment"><b>Comentarii:</b>  <span [innerHTML]="comment"></span></div>
    <div ng2FileDrop
         (fileOver)="fileOver($event)"
         [uploader]="uploader"
         class="file-drop-zone">

        <input type="file" id="file-uploader-{{itemAlias}}"
               ng2FileSelect
               [uploader]="uploader"
               accept=".pdf"
               [disabled]="uploader.isUploading"/>
        <label for="file-uploader-{{itemAlias}}" [class.file-over]="dropZoneFileOver" tabindex="-1">
            <div *ngIf="form.value; else nofile" target="_blank" rel="noopener">
                <a class="filename" [href]="form.value"> <span>{{ filename }}</span></a>
                <span class="uploader-text">Modifică {{fileDescriptionName}}</span>
                <span class="uploader-text">pdf, max. 10 MB.</span>
            </div>
            <ng-template #nofile>
                <span class="filename"><div [id]="'UploadAnimation' + '-' + itemAlias" class="spacinganim"></div></span>
                <span class="uploader-text">Încarcă {{fileDescriptionName}} în format PDF aici*</span>
                <span class="uploader-text">pdf, max. 10 MB.</span>
            </ng-template>

            <ng-container *ngIf="form.errors; else nofileerror">
                <span *ngIf="form.errors?.upload || form.errors?.unknown" class="error">Eroare la încărcarea fișierului pe server</span>
                <span *ngIf="form.errors?.fileType" class="error">Fișier invalid</span>
                <span *ngIf="form.errors?.fileSize" class="error">Fișierul depășește 10 MB</span>
            </ng-container>
            <ng-template #nofileerror>
                <span class="uploader-text">Fă click sau aruncă un fișier aici.</span>
            </ng-template>
        </label>
    </div>
</section>
