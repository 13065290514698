import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ThemeService} from '../theme.service';

@Component({
    selector: 'practica-main',
    templateUrl: './practica.component.html',
    styleUrls: ['./practica.component.scss'],
})
export class PracticaComponent {
    favIcon: HTMLLinkElement = document.querySelector('#icon');
    constructor(
        private readonly _themeService: ThemeService,
        private readonly _route: ActivatedRoute
    ) {
        _themeService.changeTheme(_route);
        this.changeIcon();
    }
    changeIcon(): void {
        const facultyName: string = this._themeService.returnShortName(this._route);
        console.log(facultyName);
        this.favIcon.href = './assets/' + facultyName + '/favicon.ico';
    }
}
