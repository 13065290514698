import {animation} from '@angular/animations';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import lottieWeb from 'lottie-web';
import Scrollbar from 'smooth-scrollbar';

@Component({
  selector: 'practica-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit, AfterViewInit {

  @ViewChild('cardsContainer') cardsContainer;
  public cards = [
    {
      id: 'anim1',
      title: 'Îți faci un CV blană',
      description: 'Scrii acolo tot ce ai mai bun despre tine și îl încarci pe portal, după ce te autentifici cu contul de @student.upt.ro'
    },
    {
      id: 'anim2',
      title: 'Aplici la companiile din portal',
      description: 'Aplici la cât mai multe posturi, cele care îți plac cel mai tare. Toate-s faine!'
    },
    {
      id: 'anim3',
      title: 'Aștepți răspunsuri',
      description: 'Răspunsul nu o să vină instant, nu te panica. Nu o să rămâi fără un stagiu de practică.'
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    Scrollbar.init(this.cardsContainer.nativeElement);
    lottieWeb.loadAnimation({
      container: document.getElementById('anim1'),
      path: './assets/cv-anim.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'CV animation',
    });
    lottieWeb.loadAnimation({
      container: document.getElementById('anim2'),
      path: './assets/choices-anim.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'Choices animation',
    });
    lottieWeb.loadAnimation({
      container: document.getElementById('anim3'),
      path: './assets/clock-anim.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'Clock animation',
    });
  }

}
