import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import { User } from "@practica/graphql/fragments/__generated__/User";
import { GraphQLService } from "@practica/graphql/graphql.service";
import { Token } from "@practica/graphql/graphql.types";
import * as Raven from "raven-js";

import { RavenWrapper } from "../../RavenWrapper";
import { ThemeService } from "../../theme.service";

@Injectable()
export class AuthService {
  public static readonly LS_KEY_TOKEN = "token";
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private graphql: GraphQLService,
    private router: Router,
    private raven: RavenWrapper,
    private readonly _themeService: ThemeService
  ) {}

  public getToken(): Token | null {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem(AuthService.LS_KEY_TOKEN));
    }

    return null;
  }

  public saveToken(token: Token) {
    if (!token) {
      throw new Error("token is null!");
    }
    if (isPlatformBrowser(this.platformId)) {
      // be sure to save only the needed fields into local storage
      const safeToken: Token = <Token>{
        id: token.id,
        key: token.key,
        created: token.created,
        expiration: token.expiration,
      };
      localStorage.setItem(AuthService.LS_KEY_TOKEN, JSON.stringify(safeToken));
    }
  }

  public clearToken() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(AuthService.LS_KEY_TOKEN, null);
    }
  }

  public login(
    token: Token,
    route: ActivatedRoute,
    user?: User,
    redirect: boolean = true
  ) {
    this.saveToken(token);
    if (user) {
      this.raven.setUserContext({
        id: user.id,
        username: user.username,
        email: user.email,
      });
    } else {
      this.raven.setUserContext();
    }
    return redirect ? this.navigate(route) : Promise.resolve(true);
  }

  public logout(activatedRoute: ActivatedRoute, redirect: boolean = true) {
    if (this.getToken()) {
      this.clearToken();
      if (redirect) {
        const shortName = this._themeService.returnShortName(activatedRoute);
        return this.router.navigate(["/" + shortName]);
      }
    }

    this.raven.setUserContext();
    return Promise.resolve(true);
  }

  public navigate(activatedRoute: ActivatedRoute) {
    const loggedIn = Boolean(this.getToken());
    const route = loggedIn ? "/user/profile" : "/user/login";
    const faculty = this._themeService.returnCachedShortName();
    return this.router.navigate(["/" + faculty + route]);
  }
}
