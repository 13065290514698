import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {CompanyInfo} from '@practica/graphql/graphql.types';

@Injectable()
export class CompanyListResolve  {
    constructor(private graphql: GraphQLService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.graphql.resolve.companies(false, route.paramMap.get('name'));
    }
}
