import {ApolloClientOptions, ApolloLink, InMemoryCache, NormalizedCacheObject} from '@apollo/client/core';


export function cacheKeyCompanyNode(slug: string): string {
    return 'CompanyNode_slug_' + slug;
}


export function dataIdFromObject(obj: any): string {
    switch (obj.__typename) {
        case 'CompanyNode':
            return cacheKeyCompanyNode(obj.slug);
        default:
            return obj.id || obj._id;
    }
}

export function getApolloOptions(link: ApolloLink): ApolloClientOptions<NormalizedCacheObject> {
    return <ApolloClientOptions<NormalizedCacheObject>><unknown>{
        link: link,
        cache: new InMemoryCache({
            dataIdFromObject: dataIdFromObject,
            addTypename: true
        })
    };
}
