import {LocationStrategy, isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

@Injectable()
export class ScrollService {
    private _isPopState = false;
    private _routeScrollPositions: { [url: string]: number } = {};
    private _scrollTarget: Element = null;
    private _deferredRestore = false;
    private _previousUrl: string = null;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private router: Router,
                private locStrat: LocationStrategy) {
    }

    /**
     * Return the document scroll offsets of an element - i.e. the x and y that must be passed to window.scrollTo(x, y) in order to
     * align the element to the topleft of the viewport.
     *
     * @param {Element} el target element
     * @returns {any} el's scroll offsets
     */
    public static scrollOffset(el: Element) {
        const body = document.body;
        if (body === el) {
            return {
                top: body.offsetTop,
                left: body.offsetLeft
            };
        }

        let box = {top: 0, left: 0};
        if (typeof el.getBoundingClientRect !== 'undefined') {
            // If we don't have gBCR, just use 0,0 rather than error
            // BlackBerry 5, iOS 3 (original iPhone)
            box = el.getBoundingClientRect();
        }

        const docEl = document.documentElement;
        const clientTop = docEl.clientTop || body.clientTop || 0;
        const clientLeft = docEl.clientLeft || body.clientLeft || 0;
        const scrollTop = window.pageYOffset || docEl.scrollTop;
        const scrollLeft = window.pageXOffset || docEl.scrollLeft;

        return {
            top: box.top + scrollTop - clientTop,
            left: box.left + scrollLeft - clientLeft
        };
    }

    /**
     * Check if the body clientHeight is big enough to allow immediate scrolling to the given vertical offset.
     *
     * @param {number} scroll desired scroll offset, in pixels from the document top
     * @returns {boolean} true if the given scroll offset can be reached
     */
    private static canScrollToOffset(scroll: number): boolean {
        return scroll + window.innerHeight <= ScrollService.scrollHeight + 1;
    }

    /**
     * Return the maximum value in numbers while ignoring ``undefined``, ``NaN``, etc
     *
     * @param {number} numbers numbers
     * @returns {number} maximal numeric value
     */
    private static maxNaN(...numbers: number[]): number {
        return Math.max.apply(Math, numbers.filter(x => !isNaN(x)));
    }

    /**
     * Initialise the scroll service if in a browser. Method is nguniversal-safe.
     */
    public install() {
        if (isPlatformBrowser(this.platformId)) {
            // prevent nguniversal problems
            this.addNavigationScrollListeners();
        }
    }

    /**
     * Current url for use as cache key.
     */
    public get cleanedUrl() {
        return this.cleanUrl(this.router.url);
    }

    /**
     * Strip the fragment from the current url for use as cache key.
     * @returns {string} stripped url
     */
    public cleanUrl(url: string): string {
        if (url) {
            const urlTree = this.router.parseUrl(url);
            if (urlTree) {
                urlTree.fragment = null;
                return this.router.serializeUrl(urlTree);
            }
        }

        return url;
    }

    /**
     * Perform any pending scroll restoration operation. This method should be called from the
     * ``ngAfterContentChecked`` of a component to improve resposniveness.
     */
    public restorePendingScroll() {
        if (this._deferredRestore) {
            // ngAfterContentChecked is used to try and restore the scroll position in the
            // same tick as the first document reflow which makes it possible (i.e. before setTimeout)
            // this could prevent scroll flashes/jankiness
            this.restoreScroll(this.cleanedUrl);
        }
        if (this._scrollTarget && this.scrollIntoView(this._scrollTarget)) {
            this._scrollTarget = null;
        }
    }

    /**
     * Get the real scroll height of the document body. This is the value for which ``scrollHeight - window.innerHeight`` equals
     * the maximum possible scroll position (``window.pageYOffset``).
     *
     * @returns {number} body scroll height
     */
    public static get scrollHeight() {
        // https://stackoverflow.com/a/17698713/3194671
        return this.maxNaN(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
    }

    /**
     * Save the current ``window.pageYOffset`` in {@link _routeScrollPositions}, keyed by the given url.
     *
     * @param url scroll route
     */
    private saveScroll(url) {
        this._routeScrollPositions[url] = window.pageYOffset;
    }

    /**
     * Try to immediately scroll the given element into view. The scroll is performed iff the page is tall
     * enough to align the element's top edge to the viewport.
     *
     * @param {Element} el target element
     * @returns {boolean} true if scrolling was performed
     */
    private scrollIntoView(el: Element): boolean {
        if (el) {
            const scrollTargetOffset = ScrollService.scrollOffset(el).top;
            if (ScrollService.canScrollToOffset(scrollTargetOffset)) {
                el.scrollIntoView(true);
                return true;
            }
        }

        return false;
    }

    /**
     * Attempt to restore the scroll position for the given url. The scroll position is restored only if the body clientHeight is big
     * enough to accomodate it. {@link _deferredRestore} is reset to false on success or if there is no saved scroll posiiton for the url.
     *
     * @param url key in {@link _routeScrollPositions}
     * @param force call ``window.scrollTo`` even if the saved scroll position is out of range
     * @returns {boolean} true if the scroll position was changed
     */
    private restoreScroll(url: string, force = false): boolean {
        const savedScroll = this._routeScrollPositions[url];
        if (savedScroll === undefined) {
            // no saved scroll position for this url :(
            this._deferredRestore = false;
            return false;
        }

        if (ScrollService.canScrollToOffset(savedScroll) || force) {
            // document is already tall enough to scroll directly
            window.scrollTo(0, savedScroll);
            this._deferredRestore = false;
            return true;
        }

        return false;
    }

    private addNavigationScrollListeners() {
        // force scroll position at top of page when route changes through routerLink navigation
        //  (and not when it changes through browser back/forward)
        // https://github.com/angular/angular/issues/10929#issuecomment-372265497
        // remember and restore scroll positions when navigating using back/forward
        //  https://github.com/angular/angular/issues/10929#issuecomment-274264962

        try {
            if ('scrollRestoration' in history) {
                // disable automatic scroll restoration by browsers, since it's doing a bad job
                // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
                history.scrollRestoration = 'manual';
            }
        } catch (e) {
            console.log(e);
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // the position should not be saved at NavigationStart during popstate navigation because it will already be mangled
                if (!this._isPopState) {
                    // save scroll position of urls on router navigation
                    // at NavigationStart, router.url is still the url of the current route (not the target of the navigation)
                    this.saveScroll(this.cleanedUrl);
                }
                this._scrollTarget = null;
            }

            if (event instanceof NavigationEnd) {
                this._scrollTarget = null;
                if (this.cleanUrl(this.router.url) !== this.cleanUrl(this._previousUrl)) {
                    if (this._isPopState) {
                        // popstate navigation, try to restore saved scroll position immediately
                        // immediate restoration might be possible if the source view is taller than the target view
                        if (!this.restoreScroll(event.url)) {
                            // document is too short, and restoring the saved scroll position would not work;
                            // defer the restoration to the next tick, when document should be reflowed and reach its target height
                            setTimeout(() => {
                                if (this._deferredRestore) {
                                    this.restoreScroll(event.url, true);
                                    this._deferredRestore = false;
                                }
                            });

                            // using _deferredRestore, the route is marked for restoring its scroll position at a later time
                            // attempts are made to restore the scroll position in ngAfterContentChecked; it's most likely that this will
                            // happen in the current tick, but setTimeout is added just in case
                            this._deferredRestore = true;
                        }
                    } else {
                        const url = this.router.parseUrl(this.router.url);
                        let scrollTarget: Element = null;
                        if (url && url.fragment) {
                            scrollTarget = document.querySelector(`#${url.fragment}`);
                            if (!scrollTarget) {
                                scrollTarget = document.querySelector(`[name="${url.fragment}"]`);
                            }
                        }

                        if (scrollTarget) {
                            // on navigation, scroll to any element referenced by the url fragment
                            if (!this.scrollIntoView(scrollTarget)) {
                                // page might not yet be fully loaded, apply the same logic of deferring
                                // the scroll operation to the next tick and ngAfterViewChecked
                                this._scrollTarget = scrollTarget;
                                setTimeout(() => {
                                    if (this._scrollTarget) {
                                        this._scrollTarget.scrollIntoView(true);
                                        this._scrollTarget = null;
                                    }
                                });
                            }
                        } else {
                            // scroll to top on regular router navigation
                            window.scrollTo(0, 0);
                        }
                    }
                }

                // end of navigation event, remove _isPopState flag
                this._isPopState = false;
                this._previousUrl = this.router.url;
            }
        });

        this.locStrat.onPopState(() => {
            // during a navigation event we must know if it was triggered by popstate navigation or regular router navigation
            this._isPopState = true;

            // on browser back/forward navigation, popstate is fired before any Navigation or other router events
            // router.url is still the current route and the position must be saved here because it can be
            // mangled by browser behavior before reaching NavigationStart
            this.saveScroll(this.cleanedUrl);
        });
    }
}
