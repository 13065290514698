import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { FetchResult } from "@apollo/client/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { AuthService } from "@practica/auth/auth.service";
import { User_student } from "@practica/graphql/fragments/__generated__/User";
import { GraphQLService } from "@practica/graphql/graphql.service";
import { User } from "@practica/graphql/graphql.types";
import {
  UpdateProfile,
  UpdateProfileVariables,
  UpdateProfile_updateProfile,
} from "@practica/graphql/mutations/__generated__/UpdateProfile";
import { UserProfile } from "@practica/graphql/queries/__generated__/UserProfile";
import { DeleteModalComponent } from "@practica/pages/user-profile/delete-modal/delete-modal.component";

import { GraphQLError } from "graphql";
import { FileUploader } from "ng2-file-upload";
import { FileLikeObject } from "ng2-file-upload/file-upload/file-like-object.class";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subscription, finalize, first } from "rxjs";
import lottieWeb from "lottie-web";
import { ThemeService } from "../../../theme.service";

const CV_UPLOAD_URL = "/upload/cv";

@Component({
  selector: "practica-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription: Subscription;
  private readonly URL_PATTERN =
    /^(http(?:s)?:\/\/)?((?:www.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)(\/[a-zA-Z0-9]+)/;

  // TODO: control enable/disable from server side
  public applicationsClosed = false;

  user: User;
  profile: User_student;

  uploader: FileUploader = new FileUploader({
    url: CV_UPLOAD_URL,
    itemAlias: "cv",
    autoUpload: true,
    removeAfterUpload: true,
    queueLimit: 1,
    allowedFileType: ["doc", "pdf"],
    maxFileSize: 10 * 1024 * 1024,
    authTokenHeader: "Authorization",
    authToken: this.auth.getToken()
      ? `Bearer ${this.auth.getToken().key}`
      : null,
  });
  dropZoneFileOver = false;
  formcv: UntypedFormControl = null;

  profileForm: UntypedFormGroup;
  companiesForm: UntypedFormGroup;
  selectedCompanies: Set<string> = new Set<string>();
  deleteConfirmationModal: BsModalRef;
  showIncompleteProfileAlert: boolean;
  showCompleteProfileAlert: boolean;
  goToCompaniesUrl: string = "/user/companies";
  goToDocumentsUrl: string = "/user/documents";

  readonly SECTION_COMPANIES = "applications";

  readonly SECTION_TITLE = {};

  constructor(
    public auth: AuthService,
    private fb: UntypedFormBuilder,
    private graphql: GraphQLService,
    private route: ActivatedRoute,
    private loadingBar: LoadingBarService,
    private modalService: BsModalService,
    private themeService: ThemeService // private themeService: ThemeService,
  ) {
    this.SECTION_TITLE[this.SECTION_COMPANIES] =
      "Aplic la una din companiile partenere";
  }

  get cvname() {
    const cvurl = decodeURI(this.formcv.value);
    return cvurl.substring(cvurl.lastIndexOf("/") + 1);
  }

   ngOnInit() {
    const data = this.route.snapshot.data;
    if (data && data.data) {
      this.onDataReceived(data.data);
    } else {
      this.subscription = this.graphql
        .profile()
        .valueChanges.subscribe((result) => {
          if (result.data) {
            this.onDataReceived(result.data);
          }
        });
    }
    this.goToCompaniesUrl = "/" + this.themeService.returnShortName(this.route) + this.goToCompaniesUrl;
    this.goToDocumentsUrl = "/" + this.themeService.returnShortName(this.route) + this.goToDocumentsUrl;
  }
  ngAfterViewInit(): void {
    lottieWeb.loadAnimation({
      container: document.getElementById("UploadAnimation"),
      path: "./assets/file-upload.json",
      renderer: "svg",
      loop: true,
      autoplay: true,
      name: "Anim",
    });
  }

  private onDataReceived(data: UserProfile) {
    if (!data.me || !data.me.student) {
      return;
    }

    this.user = data.me;
    this.profile = data.me.student;
    for (const e of data.me.student.applications.edges) {
      this.selectedCompanies.add(e.node.id);
    }

    this.profileForm = this.fb.group({
      phone: [this.profile.phone],
      linkedin: [this.profile.linkedin, Validators.pattern(this.URL_PATTERN)],
    });
    this.companiesForm = this.fb.group({
      github: [this.profile.github, Validators.pattern(this.URL_PATTERN)],
    });
    this.formcv = new UntypedFormControl(this.profile.cv || null);

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (status === 201 && headers.hasOwnProperty("location")) {
        this.formcv.setValue(headers["location"]);
        this.formcv.setErrors(null);
      } else {
        this.formcv.setErrors({ unknown: true });
      }
    };

    this.uploader.onErrorItem = () => {
      this.formcv.setErrors({ upload: true });
    };

    this.uploader.onBeforeUploadItem = () => this.loadingBar.start(5);
    this.uploader.onProgressAll = (progess) =>
      this.loadingBar.set(Math.max(5, progess));
    this.uploader.onCompleteAll = () => this.loadingBar.complete();

    this.uploader.onAfterAddingFile = () => {
      this.formcv.setErrors(null);
    };

    this.uploader.onWhenAddingFileFailed = (
      item: FileLikeObject,
      filter: any
    ) => {
      if (filter && filter.name === "fileSize") {
        this.formcv.setErrors({ fileSize: true });
      } else {
        this.formcv.setErrors({ fileType: true });
      }
    };

    if (this.applicationsClosed) {
      this.companiesForm.disable();
    }
    if (this.hasCompleteProfile()) this.showCompleteProfileAlert = true;
    else this.showIncompleteProfileAlert = true;
  }

  private buildInput(form: UntypedFormGroup) {
    const input: { [field: string]: any } = {};
    for (const entry of Object.entries(form.controls)) {
      input[entry[0]] = entry[1].value;
    }
    return input;
  }

  private handleMutation<RT, DT>(
    mutation: Observable<FetchResult<RT>>,
    form: UntypedFormGroup,
    dataGetter: (data: RT) => DT | null,
    onSuccess?: (result: DT) => boolean,
    onError?: (err?: Error | ReadonlyArray<GraphQLError>) => void
  ) {
    mutation
      .pipe(
        first(),
        finalize(() => form.enable())
      )
      .subscribe(
        (result) => {
          if (result.data && !result.errors) {
            const data = dataGetter(<RT>result.data);
            const errors = data && data["errors"] ? data["errors"] : null;
            if (data && !errors) {
              const success = onSuccess ? onSuccess(data) : true;
              if (!success && onError) {
                onError();
              }
            } else {
              if (errors && onError) {
                onError(errors);
              }
            }
          } else {
            if (result.errors && onError) {
              onError(result.errors);
            }
          }
        },
        (err) => {
          if (onError) {
            onError(err);
          }
        }
      );
  }

  private handleProfileMutation(
    mutation: Observable<FetchResult<UpdateProfile>>,
    form: UntypedFormGroup,
    onSuccess?: (result: UpdateProfile_updateProfile) => boolean,
    onError?: (err?: Error | any[]) => void
  ) {
    this.handleMutation(
      mutation,
      form,
      (data) => data.updateProfile,
      (result) => {
        const success =
          result.profile && (onSuccess ? onSuccess(result) : true);
        if (success) {
          this.profile = result.profile;
          if (this.hasCompleteProfile()) {
            this.showIncompleteProfileAlert = false;
            this.showCompleteProfileAlert = true;
          }
        }

        return success;
      },
      onError
    );
  }

  private promptConfirmation(
    inputKey: string,
    form: UntypedFormGroup,
    action: () => void
  ) {
    form.disable();
    this.deleteConfirmationModal = this.modalService.show(
      DeleteModalComponent,
      {
        initialState: { sectionTitle: this.SECTION_TITLE[inputKey] },
      }
    );
    this.deleteConfirmationModal.content.onClose.subscribe((confirmed) => {
      if (confirmed) {
        action();
      }
      form.enable();
    });
  }

  savePart(inputKey: string, inputForm: UntypedFormGroup) {
    inputForm.disable();
    const input = {};

    input[inputKey] = this.buildInput(inputForm);

    const mutation = this.graphql.updateProfile(<UpdateProfileVariables>{
      input: input,
    });
    this.handleProfileMutation(mutation, inputForm, (data) => {
      return Boolean(data.profile);
    });
    return;
  }

  deletePart(inputKey: string, inputForm: UntypedFormGroup) {
    this.promptConfirmation(inputKey, inputForm, () => {
      const variables: UpdateProfileVariables = { input: {} };
      variables.input.deletions = [inputKey];

      const mutation = this.graphql.updateProfile(variables);
      this.handleProfileMutation(mutation, inputForm, () => {
        inputForm.reset();
        return true;
      });
    });
  }

  saveProfile(form: UntypedFormGroup) {
    form.disable();

    const URL_FIELDS = ["github", "linkedin"];
    for (const entry of Object.entries(form.controls)) {
      if (URL_FIELDS.includes(entry[0])) {
        let url: string = entry[1].value;
        if (url && !url.includes("://")) {
          url = `http://${url}`;
          entry[1].setValue(url);
        }
      }
    }
    const input = this.buildInput(form);
    delete input["cv"];

    const mutation = this.graphql.updateProfile(<UpdateProfileVariables>{
      input: input,
    });
    this.handleProfileMutation(mutation, form, (data) => {
      return Boolean(data.profile);
    });
  }

  fileOver(e: boolean) {
    this.dropZoneFileOver = e;
  }

  capitalizeTheFirstLetterOfEachWord(words) {
    const separateWord = words.toLowerCase().split(" ");
    for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
  }

  hasCompleteProfile(): boolean {
    return !!this.profile.cv;
  }

  closeIncompleteProfileAlert(): void {
    this.showIncompleteProfileAlert = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
