<div class="row">
    <div class="header-info col-lg-7 col-12 px-0 px-lg-0 px-3 d-flex">
        <h1>
            <span class="primary">{{Hero1}} </span>
            <span class="secondary">{{Hero2}}</span>
            <span class="primary">.</span>
        </h1>
        <p class="py-4">
            {{SubHero}}
        </p>
        <div class="d-flex">
            <a class="primary-btn"
               href="{{facultyShortName}}"
            >
                Descoperă ofertele
            </a>
            <a class="secondary-btn ml" [routerLink]="'faq'">Întrebări frecvente</a>
        </div>

    </div>
    <div class="header-image col-lg-5 px-0 d-none d-lg-flex">
        <div id="headerAnimation"></div>
    </div>

</div>
