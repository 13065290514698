import {Component, OnInit} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {ThemeService} from "../../../theme.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'practica-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    constructor(
        private TitleService: Title,
        private readonly _themeService: ThemeService,
        private readonly _route: ActivatedRoute) {
    }

    ngOnInit() {
        this.TitleService.setTitle('Practică - ' + this._themeService.returnFacultyName(this._route));
    }
}
