import {ApolloLink, Observable, Operation} from '@apollo/client/core';

import {ExecutionResult, GraphQLError} from 'graphql';
import {RavenWrapper} from '../../RavenWrapper';

export interface PracticaError {
    field: string | null;
    messages: string[] | null;
}

export namespace PracticaErrorLink {
    export interface ErrorResponse {
        graphQLErrors?: ReadonlyArray<GraphQLError>;
        applicationErrors?: PracticaError[];
        networkError?: Error;
        response?: ExecutionResult;
        operation: Operation;
    }

    export type ErrorHandler = (error: PracticaErrorLink.ErrorResponse) => void;
}

export class PracticaErrorLink extends ApolloLink {
    constructor(raven: RavenWrapper, errorHandler: PracticaErrorLink.ErrorHandler) {
        super((operation, forward) => {
            return new Observable(observer => {
                let sub;
                try {
                    sub = forward(operation).subscribe({
                        next: result => {
                            try {
                                let graphQLErrors: ReadonlyArray<GraphQLError>, applicationErrors: PracticaError[];
                                if (result.errors) {
                                    graphQLErrors = result.errors;
                                }
                                if (result.data) {
                                    for (const resultField in result.data) {
                                        // Some mutations return validation errors as an 'errors' field on the result object
                                        if (result.data.hasOwnProperty(resultField) && result.data[resultField]) {
                                            if (result.data[resultField].hasOwnProperty('errors') && result.data[resultField]['errors']) {
                                                applicationErrors = result.data[resultField]['errors'];
                                            }
                                        }
                                    }
                                }

                                if (graphQLErrors || applicationErrors) {
                                    errorHandler({
                                        graphQLErrors: graphQLErrors,
                                        applicationErrors: applicationErrors,
                                        response: result,
                                        operation,
                                    });
                                }
                            } catch (e) {
                                raven.captureException(e);
                            }
                            observer.next(result);
                        },
                        error: networkError => {
                            try {
                                errorHandler({
                                    operation,
                                    networkError,
                                    // Network errors can return GraphQL errors on for example a 403
                                    graphQLErrors: networkError.result && networkError.result.errors,
                                });
                            } catch (e) {
                                raven.captureException(e);
                            }
                            observer.error(networkError);
                        },
                        complete: observer.complete.bind(observer),
                    });
                } catch (e) {
                    try {
                        errorHandler({networkError: e, operation});
                    } catch (e) {
                        raven.captureException(e);
                    }
                    observer.error(e);
                }

                return () => {
                    if (sub) {
                        sub.unsubscribe();
                    }
                };
            });
        });
    }
}
