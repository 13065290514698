import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '@practica/auth/auth.service';
import {CompanyInfo} from '@practica/graphql/fragments/__generated__/CompanyInfo';
import {User_student} from '@practica/graphql/fragments/__generated__/User';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {Internship} from '@practica/graphql/graphql.types';
import {CompanyContact} from '@practica/graphql/graphql.types';
import {ThemeService} from "../../../theme.service";

@Component({
    selector: 'practica-user-company-internships',
    templateUrl: './user-company-internships.component.html',
    styleUrls: ['./user-company-internships.component.scss']
})
export class UserCompanyInternshipsComponent implements OnInit {
    company: CompanyInfo;
    internships: Internship[];
    isMobile: boolean = screen.width <= 768;
    selectedInternshipIndex = this.isMobile ? -2 : -1;
    profile: User_student;
    applicationsIds: string[] = [];
    contacts: CompanyContact[];
    openModal: boolean = false;

    constructor(private route: ActivatedRoute, private graphql: GraphQLService, private authService: AuthService,
                private themeService: ThemeService) {
    }

    async ngOnInit() {
        this.route.paramMap.subscribe(paramMap => {
            const slug = paramMap.get('slug');
            let facultyCode = this.themeService.returnCachedShortName();
            this.graphql.companyBySlug(slug, facultyCode).valueChanges.subscribe((result) => {
                this.company = <CompanyInfo>result.data.companyBySlug;
                if (result.data.companyBySlug) {
                    this.contacts = result.data.companyBySlug.contacts.edges.map(edge => edge.node);
                    this.internships = result.data.companyBySlug.internships.edges.map(edge => edge.node);
                }
            });
        });
        if (!!this.authService.getToken()) {
            const gqlProfile = await this.graphql.resolve.profile().then(profile => {
                if (!profile || !profile.me) {
                    // token was probably invalid or expired
                    this.authService.logout(this.route);
                }
                return profile;
            });
            this.profile = gqlProfile.me?.student;
            this.applicationsIds = this.profile.applications.edges.map(application => application.node.id);
        }
    }

    handleSelection(index: number): void {
        this.selectedInternshipIndex = index;
        if (this.isMobile && this.selectedInternshipIndex > -2) {
            this.openModal = true;
        }
    }

    async addOrRemoveApplication(id: string) {
        if (this.applicationsIds.includes(id)) {
            await this.graphql.removeApplication(id).subscribe(result => {
                this.profile = result.data.removeApplication?.profile;
                this.applicationsIds = this.profile.applications.edges.map(application => application.node.id);
            });
        } else {
            await this.graphql.addApplication(id).subscribe(result => {
                this.profile = result.data.addApplication?.profile;
                this.applicationsIds = this.profile.applications.edges.map(application => application.node.id);
            });
        }
    }

    hasApplicationForInternship(index: number): boolean {
        return this.applicationsIds.includes(this.internships[index].id);
    }

    closeModal(): void {
        this.selectedInternshipIndex = -2;
        this.openModal = false;
    }
}
