<div id="banner" role="banner" class="h-14">
    <div></div>
    <div class="d-flex justify-content-between align-items-center w-100">
        <h1 class="text-white title">
            Portalul de practică

            <br/>

            <div
                    rel="noopener noreferrer"
                    class="title"
                    style="opacity: 80%"
            >
                {{ FacultyName }}
            </div>
        </h1>

        <a
                href="https://upt.ro"
                target="_blank"
                rel="noopener noreferrer"
                class="h-100 flex"
                title="Spre site-ul UPT"
        >
            <div class="h-100 text-white d-none d-md-flex -mr-px">
                <practica-upt-logo></practica-upt-logo>
            </div>

            <div class="h-100 text-white d-flex d-md-none">
                <practica-upt-short-logo></practica-upt-short-logo>
            </div>
        </a>
    </div>

    <div class="bg-white"></div>
</div>

<nav *ngIf="FacultyName && FacultyLogo">
    <div class="container">
        <div class=" d-flex justify-content-between align-items-center py-2">
            <a href="{{ShortName}}">
                <div class="d-flex align-items-center" style="height: 100%"><i class="gg-home-alt"></i></div>
            </a>
            <div *ngIf="showButton()">
                <div class="logo d-none d-md-flex" style="margin-left: 230px">
                    <img src="{{FacultyLogo}}" alt="Practica">
                </div>
            </div>
            <div *ngIf="hideButton()">
                <div class="logo d-none d-md-flex" style="margin-left: 120px">
                    <img src="{{FacultyLogo}}" alt="Practica">
                </div>
            </div>

                <div *ngIf="showButton()">
                    <a [routerLink]=[AccountPath] class="practica-btn acc-button">
                        Contul tău
                    </a>
                    <button class="practica-btn secondary-button" (click)="handleLogoutButtonClick()">
                        Deconectează-te
                    </button>
                </div>
                <div *ngIf="hideButton()">
                    <a [routerLink]=[LoginPath] class="practica-btn login-button">
                        Intră în contul de student
                    </a>
                    <a [routerLink]="'/company/register'" class="practica-btn secondary-button mr-3 d-none d-md-inline-flex">
                        Intră în contul de companie
                    </a>
                </div>
        </div>
    </div>
</nav>
