import {Injectable} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import {AuthService} from '@practica/auth/auth.service';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {UserProfile} from '@practica/graphql/queries/__generated__/UserProfile';

@Injectable()
export class UserProfileResolve  {
    constructor(private graphql: GraphQLService, private auth: AuthService, private activatedRoute: ActivatedRoute) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<UserProfile> {
        return this.graphql.resolve.profile().then(profile => {
            if (!profile || !profile.me) {
                // token was probably invalid or expired
                this.auth.logout(this.activatedRoute);
            }
            return profile;
        });
    }
}
