import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {FacultyType} from '@practica/graphql/graphql.types';

@Injectable()
export class CompanyRegisterResolve  {
    constructor(private graphql: GraphQLService) { }
    resolve(route: ActivatedRouteSnapshot): Promise<FacultyType[]> {
        return this.graphql.resolve.faculties().then(faculties => faculties);
    }
}
