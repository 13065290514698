<section class="practica-colored-wrapper d-flex flex-column w-100">
    <div class="d-flex w-100 justify-content-between">
        <h4 class="section-title pb-5">Formular de înregistrare a companiei</h4>
        <button class="practica-btn login-button" (click)="redirectToCompanyLogin()">Am deja cont</button>
    </div>
    <div class="alert alert-danger mb-5" role="alert" *ngIf="formErrors.length > 0">
        <ul>
            <li *ngFor="let error of formErrors">{{error}}</li>
        </ul>
    </div>
    <div class="alert alert-danger mb-5" role="alert" *ngIf="backendErrors.length > 0">
        <ul>
            <li *ngFor="let error of backendErrors">{{error}}</li>
        </ul>
    </div>
    <form [formGroup]="registerForm" (ngSubmit)="send(registerForm)">
        <fieldset class="d-flex flex-column align-items-center w-100">
            <div class="row w-100">
                <div class="col-md-6 col-12">
                    <h4 class="pb-2">Date personale</h4>
                    <label>
                        <i class="gg-profile"></i>
                        <input type="tel" aria-label="First name" placeholder="Prenume"
                               formControlName="firstName">
                    </label>
                    <label>
                        <i class="gg-profile"></i>
                        <input type="tel" aria-label="Last name" placeholder="Nume"
                               formControlName="lastName">
                    </label>
                    <label>
                        <i class="gg-smartphone"></i>
                        <input type="tel" aria-label="Phone number" placeholder="Număr de telefon"
                               formControlName="phone">
                    </label>
                </div>
                <div class="col-md-6 col-12">
                    <h4 class="pb-2 mt-md-0 mt-5">Date administrative</h4>
                    <label>
                        <i class="gg-smartphone"></i>
                        <input type="tel" aria-label="Company name" placeholder="Numele companiei"
                               formControlName="companyName">
                    </label>
                    <label>
                        <i class="gg-list-tree"></i>
                        <div class="w-100 margin-left">
                            <ng-select [items]="faculties"
                                       bindLabel="name"
                                       bindValue="id"
                                       [multiple]="true"
                                       placeholder="Selectează facultățile"
                                       hideSelected="true"
                                       (change)="onFacultySelection($event)">
                            </ng-select>
                        </div>
                    </label>
                    <label *ngIf="selectedFaculties.length > 0">
                        <i class="gg-list-tree"></i>
                        <div class="w-100 margin-left">
                            <ng-select [items]="facultyResponsibles"
                                       bindLabel="name"
                                       bindValue="id"
                                       placeholder="Selectează responsabilul"
                                       (change)="onFacultyResponsibleSelection($event)">
                            </ng-select>
                        </div>
                    </label>
                    <label>
                        <textarea placeholder="Observații" formControlName="observations"></textarea>
                    </label>
                </div>
            </div>
            <div class="w-100 d-flex justify-content-center">
                <div class="col-md-6 col-12 centered-width-column">
                    <h4 class="pb-2 mt-5">Cont</h4>
                    <label>
                        <i class="gg-mail"></i>
                        <input type="email" formControlName="email" aria-label="email"
                               placeholder="Adresă e-mail">
                    </label>
                    <label>
                        <i class="gg-lastpass"></i>
                        <input type="password" formControlName="password" aria-label="parola"
                               placeholder="Parolă">
                    </label>
                    <label>
                        <i class="gg-lastpass"></i>
                        <input type="password" aria-label="password2" placeholder="Confirmă parola"
                               formControlName="password2">
                    </label>
                </div>
            </div>

        </fieldset>
        <div class="d-flex justify-content-end">
            <button type="submit" class="practica-btn">Trimite</button>
        </div>
    </form>
</section>
