<main class="my-5 w-100 mx-sm-0 mx-3 h-100">
    <section class="company-details-container py-5 px-4 row w-100 h-100" *ngIf="company">
        <div class="col-md-4 col-12">
            <h4>Alege din {{internships.length}} stagii de practică la {{company.name}}.</h4>

            <div class="my-3">
                <p class="info-title">Informații generale</p>
                <button
                        class="navigation-button"
                        [ngClass]="selectedInternshipIndex == -1 ? 'active' : ''"
                        (click)="handleSelection(-1)"
                >
                    Despre companie
                </button>
            </div>

            <div class="my-3">
                <p class="info-title">Posturi disponibile</p>
                <practica-profile-internship-offers [internships]="internships"
                                                    [handleSelection]="handleSelection.bind(this)"
                                                    [selectedInternshipIndex]="selectedInternshipIndex"
                                                    [applicationsIds]="applicationsIds"></practica-profile-internship-offers>
            </div>
        </div>
        <div class="col-8 d-none d-md-block">
            <practica-offer-details
                    class="h-100"
                    [company]="company"
                    [contacts]="contacts"
                    [internship]="selectedInternshipIndex >= 0 ? internships[selectedInternshipIndex] : undefined"
                    [showCompanyDetails]="selectedInternshipIndex == -1"
                    [hasInternshipApplication]="selectedInternshipIndex >= 0 ? hasApplicationForInternship(selectedInternshipIndex) : false"
                    [displayApplyActions]="!!profile && !!profile.cv"
                    (addOrRemoveApplicationEvent)="addOrRemoveApplication($event)"
                    *ngIf="selectedInternshipIndex > -2"
            ></practica-offer-details>
        </div>
    </section>
    <practica-modal class="d-md-node d-block" [open]="openModal" (closeMeEvent)="closeModal()">
        <practica-offer-details
                [company]="company"
                [contacts]="contacts"
                [internship]="selectedInternshipIndex >= 0 ? internships[selectedInternshipIndex] : undefined"
                [showCompanyDetails]="selectedInternshipIndex == -1"
                [hasInternshipApplication]="selectedInternshipIndex >= 0 ? hasApplicationForInternship(selectedInternshipIndex) : false"
                [displayApplyActions]="!!profile && !!profile.cv"
                (addOrRemoveApplicationEvent)="addOrRemoveApplication($event)"
                *ngIf="selectedInternshipIndex > -2"
        ></practica-offer-details>
    </practica-modal>
</main>
