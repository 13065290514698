import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

const themeDict = {
  ah: {
    facultyShortName: "ah",
    colorPrimary: "#4F4D5B",
    colorPrimaryShade: "#383549",
    colorPrimaryOffwhite: "#dcd6e7",
    colorSecondary: "#EED410",
    colorSecondaryShade: "#DAC316",
    facultyName: "Arhitectură și Urbanism",
    facultyLogo: "./assets/ah/logo-arh.svg",
    // facultyHeroTitle1: 'Nu știi unde faci practica?',
    // facultyHeroTitle2: 'Te ajutăm noi',
    // facultySubHero: 'Cu toții trebuie să începem de undeva, a venit momentul și pentru tine să faci asta.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/ah/header_ah_anim.json",
  },
  ac: {
    facultyShortName: "ac",
    colorPrimary: "#212750",
    colorPrimaryShade: "#14193B",
    colorPrimaryOffwhite: "#dcd6e7",
    colorSecondary: "#E99A1F",
    colorSecondaryShade: "#DE8905",
    facultyName: "Automatică și Calculatoare",
    facultyLogo: "./assets/ac/logo-ac.svg",
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/ac/header_ac_anim.json",
  },
  ci: {
    facultyShortName: "ci",
    colorPrimary: "#594A93",
    colorPrimaryShade: "#4A3E78",
    colorPrimaryOffwhite: "#dcd6e7",
    colorSecondary: "#F8B724",
    colorSecondaryShade: "#E1A621",
    facultyName: "Chimie și Inginerie Industrială",
    facultyLogo: "./assets/ci/logo-ci.svg",
    // facultyHeroTitle1: 'Cauți formula magică?',
    // facultyHeroTitle2: 'Găsește-o la practică',
    // facultySubHero: 'Cu toții trebuie să începem de undeva, a venit momentul și pentru tine să faci asta.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/ci/header_ci_anim.json",
  },
  ct: {
    facultyShortName: "ct",
    colorPrimary: "#AFB4C6",
    colorPrimaryShade: "#969AAA",
    colorPrimaryOffwhite: "#d4d4dc",
    colorSecondary: "#121111",
    colorSecondaryShade: "#231F20",
    facultyName: "Construcții",
    facultyLogo: "./assets/ct/logo-ct.jpg",
    // facultyHeroTitle1: 'De unde încep cu practica?',
    // facultyHeroTitle2: 'De aici',
    // facultySubHero: 'Cu toții trebuie să începem de undeva, a venit momentul și pentru tine să faci asta.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/ct/header_ct_anim.json",
  },
  ec: {
    facultyShortName: "ec",
    colorPrimary: "#1B7AB0",
    colorPrimaryShade: "#1B4494",
    colorPrimaryOffwhite: "#d4d4dc",
    colorSecondary: "#9E9D9D",
    colorSecondaryShade: "#61C0C3",
    facultyName: "Electronică, Telecomunicații și Tehnologii Informaționale",
    facultyLogo: "./assets/ec/logo-etc.svg",
    // facultyHeroTitle1: 'Ai idei bune?',
    // facultyHeroTitle2: 'Pune-le în aplicare la practică',
    // facultySubHero: 'Cu toții trebuie să începem de undeva, a venit momentul și pentru tine să faci asta.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/ec/header_etc_anim.json",
  },
  hd: {
    facultyShortName: "hd",
    colorPrimary: "#2E3192",
    colorPrimaryShade: "#21247C",
    colorPrimaryOffwhite: "#d4d4dc",
    colorSecondary: "#E29294",
    colorSecondaryShade: "#DB6D71",
    facultyName: "Inginerie Hunedoara",
    facultyLogo: "./assets/hd/logo-hd.svg",
    // facultyHeroTitle1: 'Ai idei bune?',
    // facultyHeroTitle2: 'Pune-le în aplicare la practică',
    // facultySubHero: 'Cu toții trebuie să începem de undeva, a venit momentul și pentru tine să faci asta.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/hd/header_hd_anim.json",
  },
  et: {
    facultyShortName: "et",
    colorPrimary: "#0000FF",
    colorPrimaryShade: "#0000A3",
    colorPrimaryOffwhite: "#d4d4dc",
    colorSecondary: "#C0C0FF",
    colorSecondaryShade: "#BABAE8",
    facultyName: "Inginerie Electrică și Energetică",
    facultyLogo: "./assets/et/logo-et.svg",
    // facultyHeroTitle1: 'La practică nu faci magie,',
    // facultyHeroTitle2: 'dar poți fi aproape',
    // facultySubHero: 'Scoate asul din mânecă și găsește stagiul de practică pentru tine.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/et/header_et_anim.json",
  },
  mg: {
    facultyShortName: "mg",
    colorPrimary: "#2A3888",
    colorPrimaryShade: "#19256A",
    colorPrimaryOffwhite: "#d4d4dc",
    colorSecondary: "#7778B5",
    colorSecondaryShade: "#434C99",
    facultyName: "Management în Producţie şi Transporturi",
    facultyLogo: "./assets/mg/logo-mg.png",
    // facultyHeroTitle1: 'Pune roțile în mișcare',
    // facultyHeroTitle2: 'și găsește-ți practica',
    // facultySubHero: 'Scoate asul din mânecă și găsește stagiul de practică pentru tine.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/mg/header_mg_anim.json",
  },
  mc: {
    facultyShortName: "mc",
    colorPrimary: "#5B2C83",
    colorPrimaryShade: "#431E63",
    colorPrimaryOffwhite: "#d4d4dc",
    colorSecondary: "#B89ECD",
    colorSecondaryShade: "#8E75A3",
    facultyName: "Mecanică",
    facultyLogo: "./assets/mc/logo-mc.svg",
    // facultyHeroTitle1: 'După ce ai găsit practica,',
    // facultyHeroTitle2: 'poți zbura',
    // facultySubHero: 'Scoate asul din mânecă și găsește stagiul de practică pentru tine.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/mc/header_mc_anim.json",
  },
  sc: {
    facultyShortName: "sc",
    colorPrimary: "#186F70",
    colorPrimaryShade: "#145556",
    colorPrimaryOffwhite: "#c3f3e2",
    colorSecondary: "#97CCBA",
    colorSecondaryShade: "#70B19B",
    facultyName: "Științe ale Comunicării",
    facultyLogo: "./assets/sc/logo-sc.svg",
    // facultyHeroTitle1: 'Folosește cuvintele portrivite și',
    // facultyHeroTitle2: 'aplică la practică',
    // facultySubHero: 'Scoate asul din mânecă și găsește stagiul de practică pentru tine.',
    facultyHeroTitle1: "Astrele s-au aliniat",
    facultyHeroTitle2: "ți-am găsit practică",
    facultySubHero:
      "Acum că mercur a ieșit din retrograd, a venit timpul să începi un stagiu de practică.",
    headerAnimation: "./assets/sc/header_sc_anim.json",
  },
};

@Injectable()
export class ThemeService {
  private _faculty = "";

  constructor() {}

  changeTheme(route: ActivatedRoute) {
    this._setFaculty(route);
    if (this._faculty) {
      document.documentElement.style.setProperty(
        "--color-primary",
        themeDict[this._faculty].colorPrimary
      );
      document.documentElement.style.setProperty(
        "--color-primary-shade",
        themeDict[this._faculty].colorPrimaryShade
      );
      document.documentElement.style.setProperty(
        "--color-primary-offwhite",
        themeDict[this._faculty].colorPrimaryOffwhite
      );
      document.documentElement.style.setProperty(
        "--color-secondary",
        themeDict[this._faculty].colorSecondary
      );
      document.documentElement.style.setProperty(
        "--color-secondary-shade",
        themeDict[this._faculty].colorSecondaryShade
      );
    }
  }
  returnFacultyName(route: ActivatedRoute): string {
    if (!this._faculty) return undefined;
    this._setFaculty(route);
    return themeDict[this._faculty].facultyName;
  }

  returnShortName(route: ActivatedRoute): string {
    if (!this._faculty) return undefined;
    this._setFaculty(route);
    return this._faculty;
  }
  returnCachedShortName(): string {
    return this._faculty;
  }

  getFacultyLogoPath(route: ActivatedRoute): string {
    if (!this._faculty) return undefined;
    this._setFaculty(route);
    return themeDict[this._faculty].facultyLogo;
  }
  private _setFaculty(route: ActivatedRoute): void {
    let param = this.getNameParam(route);

    if (param) this._faculty = param;
  }
  getHeaderAnimationPath(route: ActivatedRoute): string {
    if (!this._faculty) return undefined;
    this._setFaculty(route);
    return themeDict[this._faculty].headerAnimation;
  }
  getHeroText1(route: ActivatedRoute): string {
    if (!this._faculty) return undefined;
    this._setFaculty(route);
    return themeDict[this._faculty].facultyHeroTitle1;
  }
  getHeroText2(route: ActivatedRoute): string {
    if (!this._faculty) return undefined;
    this._setFaculty(route);
    return themeDict[this._faculty].facultyHeroTitle2;
  }
  getSubHeroText(route: ActivatedRoute): string {
    if (!this._faculty) return undefined;
    this._setFaculty(route);
    return themeDict[this._faculty].facultySubHero;
  }

  getFaculties(): any {
    return Object.keys(themeDict).map(function (key) {
      return themeDict[key];
    });
  }

  getNameParam(route: ActivatedRoute): string | null {
    let param = "name";
    if (route.snapshot.paramMap.has(param)) {
      return route.snapshot.paramMap.get(param);
    }
    if (route.parent) {
      return this.getNameParam(route.parent);
    }
    return null;
  }
}
