import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthGuard } from "@practica/auth/auth-guard.service";
import { CompanyListResolve } from "@practica/components/companies/companies.resolve";
import { CompanyDetailResolve } from "@practica/pages/company-detail/company-detail.resolve";
import { CompanyRegisterComponent } from "@practica/pages/company-register/company-register.component";
import { CompanyRegisterResolve } from "@practica/pages/company-register/company-register.resolve";
import { FacultySelectionComponent } from "@practica/pages/faculty-selection/faculty-selection.component";
import { FaqListResolve } from "@practica/pages/faq/faq.resolve";
import { LoginComponent } from "@practica/pages/login/login.component";
import { UserCompaniesComponent } from "@practica/pages/user-companies/user-companies.component";
import { UserCompanyInternshipsComponent } from "@practica/pages/user-company-internships/user-company-internships.component";
import { UserProfileComponent } from "@practica/pages/user-profile/user-profile.component";
import { UserProfileResolve } from "@practica/pages/user-profile/user-profile.resolve";
import { FaqComponent } from "./pages/faq/faq.component";
import { HomeComponent } from "./pages/home/home.component";
import { PracticaComponent } from "./practica.component";
import {UserDocumentsComponent} from "@practica/pages/user-documents/user-documents.component";
import {UserDocumentsResolve} from "@practica/pages/user-documents/user-documents.resolve";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "company",
        component: PracticaComponent,
        children: [
          {
            path: "register",
            pathMatch: "full",
            resolve: {
              data: CompanyRegisterResolve,
            },
            component: CompanyRegisterComponent,
          },
        ],
      },
      {
        path: ":name",
        component: PracticaComponent,
        children: [
          {
            path: "",
            component: HomeComponent,
            pathMatch: "full",
            data: {
              title: "",
            },
            resolve: {
              companies: CompanyListResolve,
            },
          },
          {
            path: "company/:slug",
            component: UserCompanyInternshipsComponent,
            // resolve: {
            //     company: CompanyDetailResolve
            // },
          },
          {
            path: "faq",
            component: FaqComponent,
            resolve: {
              faqs: FaqListResolve,
            },
            data: {
              title: "FAQ - Practica ",
            },
          },
          {
            path: "user/login",
            component: LoginComponent,
            canActivate: [AuthGuard],
            data: {
              title: "Log in - Practica ",
              notAuthGuard: true,
            },
          },
          {
            path: "user/profile",
            component: UserProfileComponent,
            canActivate: [AuthGuard],
            resolve: {
              data: UserProfileResolve,
            },
            data: {
              title: "Profil - Practica ",
              notAuthGuard: false,
            },
          },
          {
            path: "user/companies",
            component: UserCompaniesComponent,
            canActivate: [AuthGuard],
            resolve: {
              data: UserProfileResolve,
            },
            data: {
              title: "Profil - Practica ",
              notAuthGuard: false,
            },
          },
          {
            path: "user/documents",
            component: UserDocumentsComponent,
            // canActivate: [AuthGuard],
            resolve: {
              data: UserDocumentsResolve,
            },
            data: {
              title: "Documents - Practica ",
              notAuthGuard: false,
            },
          },
          {
            path: "user/company/:slug",
            component: UserCompanyInternshipsComponent,
            canActivate: [AuthGuard],
            resolve: {
              data: CompanyDetailResolve,
            },
            data: {
              title: "Profil - Practica ",
              notAuthGuard: false,
            },
          },
          {
            path: "user",
            redirectTo: "/user/profile",
            pathMatch: "full",
          },
          {
            path: "**",
            component: FacultySelectionComponent,
          },
        ],
      },
      {
        path: "**",
        component: PracticaComponent,
        children: [
          {
            path: "company/register",
            pathMatch: "full",
            resolve: {
              data: CompanyRegisterResolve,
            },
            component: CompanyRegisterComponent,
          },
          {
            path: "**",
            component: FacultySelectionComponent,
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
  providers: [
    FaqListResolve,
    CompanyListResolve,
    CompanyDetailResolve,
    UserProfileResolve,
    CompanyRegisterResolve,
    UserDocumentsResolve
  ],
})
export class PracticaRoutingModule {}
