import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'practica-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.close.emit();
  }

}
