<section *ngIf="showCompanyDetails && company" class="screen-width" #companyContainer>
    <div style="background-color: white; width: 300px">
        <practica-lazy-image
                alt="{{company.name}} logo" [images]="company.logo"
                sizes="(min-width: 1200px) 18vw, (min-width: 992px) 22vw, (min-width: 768px) 30vw, (min-width: 576px) 45vw, 90vw">
        </practica-lazy-image>
    </div>

    <p class="my-5" [innerHTML]="company.description">

    </p>
    <div class="contact-container" *ngIf="company.website">
        <i class="gg-website"></i>
        <span><a [href]="company.website" target="_blank">{{company.website}}</a></span>
    </div>
    <div class="contact-container" *ngIf="company.address">
        <i class="gg-pin"></i>
        <span>{{company.address}}</span>
    </div>

    <div class="contact-container" *ngIf="hasContactEmails()">
        <i class="gg-mail"></i>
        <span>{{getContactEmails()}}</span>
    </div>

    <div class="contact-container" *ngIf="hasContactPhoneNumbers()">
        <i class="gg-smartphone"></i>
        <span>{{getContactPhones()}}</span>
    </div>

</section>

<section class="d-flex flex-column justify-content-between h-100" *ngIf="!showCompanyDetails">
    <div class="screen-width" #offerContainer>
        <div class="d-flex align-items-center">
            <h3 class="section-title" [ngStyle]="hasInternshipApplication ? {'padding-right': '30px'} : {}">{{internship.title}}</h3>
            <img src="assets/check-icon.svg" alt="check_icon" *ngIf="hasInternshipApplication">
        </div>
        <div class="d-flex flex-wrap">
            <div class="internship-paid-label mb-2" *ngIf="internship.isPaid">Practică plătită</div>
            <div class="tag-label mb-2" *ngFor="let tag of internship.tags.edges">{{tag.node.name}}</div>
        </div>
        <p class="gray-bold" *ngIf="internship.capacity
                            && internship.capacity > 0"
        >
            {{internship.capacity}}
            {{internship.capacity == 1 ?
            'post disponibil' :
            'posturi disponibile'
            }}
        </p>
        <div class="mt-5" *ngIf="internship.description">
            <h3 class="mb-4">Descrierea postului</h3>
            <p [innerHTML]="internship.description"></p>
        </div>
        <div class="mt-5" *ngIf="internship.requirements">
            <h3 class="mb-4">Competențe tehnice cerute</h3>
            <p [innerHTML]="internship.requirements"></p>
        </div>
    </div>
    <div class="d-flex justify-content-end py-3 " *ngIf="displayApplyActions">
        <button class="practica-btn"
                [ngClass]="hasInternshipApplication ? 'btn-practica-secondary' : 'btn-practica-primary'"
                (click)="addOrRemoveApplication(internship.id)">
            {{  hasInternshipApplication ?
            'Șterge aplicația'
            : 'Aplică'
            }}
        </button>
    </div>
</section>
