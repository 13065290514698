import {CommonModule, isPlatformServer} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApolloLink, concat} from '@apollo/client/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {AuthGuard} from '@practica/auth/auth-guard.service';
import {AuthService} from '@practica/auth/auth.service';
import {PracticaErrorLink} from '@practica/graphql/error-link';
import {DeleteModalComponent} from '@practica/pages/user-profile/delete-modal/delete-modal.component';
import {EscapeHtmlPipe} from '@practica/pipes/escape-html.pipe';
import {HttpLink, Options} from 'apollo-angular/http';
import {FileUploadModule} from 'ng2-file-upload';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {NgxPaginationModule} from 'ngx-pagination';
import {RavenWrapper} from '../RavenWrapper';
import {AccordionGroupComponent} from './components/accordion/accordion-group.component';
import {AccordionComponent} from './components/accordion/accordion.component';
import {CompaniesComponent} from './components/companies/companies.component';
import {LazyImageComponent} from './components/lazy-image/lazy-image.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {getApolloOptions} from './graphql/graphql.config';
import {GraphQLService} from './graphql/graphql.service';
import {CompanyDetailComponent} from './pages/company-detail/company-detail.component';
import {FaqComponent} from './pages/faq/faq.component';
import {HomeComponent} from './pages/home/home.component';
import {LoginComponent} from './pages/login/login.component';
import {UserProfileComponent} from './pages/user-profile/user-profile.component';
import {KeepHtmlPipe} from './pipes/keep-html.pipe';
import {PracticaRoutingModule} from './practica-routing.module';
import {PracticaComponent} from './practica.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { UptLogoComponent } from './components/upt-logo/upt-logo.component';
import { UptShortLogoComponent } from './components/upt-short-logo/upt-short-logo.component';
import { CardsComponent } from './components/cards/cards.component';
import { CardComponent } from './components/card/card.component';
import { FooterComponent } from './components/footer/footer.component';
import { UserCompaniesComponent } from './pages/user-companies/user-companies.component';
import { UserCompanyInternshipsComponent } from './pages/user-company-internships/user-company-internships.component';
import { ProfileInternshipOffersComponent } from './components/profile-internship-offers/profile-internship-offers.component';
import {Apollo, ApolloModule} from "apollo-angular";
import { ModalComponent } from './components/modal/modal.component';
import { OfferDetailsComponent } from './components/offer-details/offer-details.component';
import { AlertComponent } from './components/alert/alert.component';
import { FacultySelectionComponent } from './pages/faculty-selection/faculty-selection.component';
import { FacultyCardComponent } from './components/faculty-card/faculty-card.component';
import { CompanyRegisterComponent } from './pages/company-register/company-register.component';
import { UserDocumentsComponent } from './pages/user-documents/user-documents.component';
import { DocumentUploaderComponent } from './components/document-uploader/document-uploader.component';

const STATE_KEY = makeStateKey<any>('apollo.state');

const NgxBootstrapModules = [
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PracticaRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxPageScrollModule,
        LoadingBarHttpClientModule,
        LoadingBarModule,
        NgxPaginationModule,
        NgSelectModule,
        FileUploadModule,
        ApolloModule,
        ...NgxBootstrapModules,
        FormsModule,
    ],
    declarations: [
        PracticaComponent,
        HomeComponent,
        CompaniesComponent,
        CompanyDetailComponent,
        KeepHtmlPipe,
        EscapeHtmlPipe,
        NavbarComponent,
        DeleteModalComponent,
        FaqComponent,
        AccordionGroupComponent,
        AccordionComponent,
        UserProfileComponent,
        LoginComponent,
        LazyImageComponent,
        HomeHeaderComponent,
        UptLogoComponent,
        UptShortLogoComponent,
        CardsComponent,
        CardComponent,
        FooterComponent,
        UserCompaniesComponent,
        UserCompanyInternshipsComponent,
        ProfileInternshipOffersComponent,
        ModalComponent,
        OfferDetailsComponent,
        AlertComponent,
        FacultySelectionComponent,
        FacultyCardComponent,
        CompanyRegisterComponent,
        UserDocumentsComponent,
        DocumentUploaderComponent,
    ],
    providers: [
        GraphQLService,
        AuthService,
        AuthGuard,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class PracticaModule {
    constructor(apollo: Apollo, httpLink: HttpLink,
                @Inject(PLATFORM_ID) private platformId: Object,
                private readonly transferState: TransferState,
                private auth: AuthService,
                private raven: RavenWrapper,
    ) {
        let gqlUrl = '/graphql';
        if (isPlatformServer(this.platformId)) {
            if (!process.env.BACKEND_URL) {
                throw new Error('the BACKEND_URL environment variable must be set when running server-side');
            }
            // absolute URL is needed on server side
            gqlUrl = process.env.BACKEND_URL + gqlUrl;
        }

        const http = httpLink.create(<Options>{uri: gqlUrl});
        const authLink = new ApolloLink((operation, forward) => {
            const token = this.auth.getToken();
            if (token) {
                operation.setContext((context) => {
                    const headers = context.headers || {};
                    headers['Authorization'] = `Bearer ${token.key}`;

                    context.headers = headers;
                    return context;
                });
            }

            return forward(operation);
        });

        const errorLink = new PracticaErrorLink(raven, ({operation, response, graphQLErrors, applicationErrors, networkError}) => {
            if (networkError && networkError['status'] === 401) {
                // TODO: better way to detect lack of authentication
                this.auth.clearToken();
            } else if (networkError && networkError['status'] >= 501) {
                console.error('backend error');
                console.log(networkError);
            } else {
                if (!raven.captureException(networkError ? networkError : 'GraphQL error', {
                    extra: {
                        operation: operation,
                        graphQLErrors: graphQLErrors,
                        applicationErrors: applicationErrors,
                        networkError: networkError,
                    }
                })) {
                    console.group('GraphQL error');
                    if (networkError) {
                        console.log(networkError);
                    }
                    if (graphQLErrors) {
                        console.log(graphQLErrors);
                    }
                    if (applicationErrors) {
                        console.log(applicationErrors);
                    }
                    console.groupEnd();
                }
            }
        });

        const options = getApolloOptions(errorLink.concat(concat(authLink, http)));
        options.ssrMode = isPlatformServer(this.platformId);
        apollo.create(options);

        if (this.transferState.hasKey(STATE_KEY)) {
            const state = this.transferState.get<any>(STATE_KEY, null);
            options.cache.restore(state);
        } else {
            this.transferState.onSerialize(STATE_KEY, () => {
                return options.cache.extract();
            });
        }
    }
}
