import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Observable, Subject, Subscription} from 'rxjs';

@Component({
    selector: 'practica-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss']
})

export class DeleteModalComponent implements OnInit {
    private _onClose: Subject<boolean> = new Subject<boolean>();
    onClose: Observable<boolean> = this._onClose.asObservable();
    sectionTitle: string;

    private subscription: Subscription;


    constructor(private modalService: BsModalService, private bsModalRef: BsModalRef) {
    }

    public ngOnInit(): void {
        this.subscription = this.modalService.onHidden.subscribe(reason => {
            if (reason != null) {
                // reason is null if modal was closed by direct call of hide() method
                this._onClose.next(false);
            }
        });
    }

    public confirm(): void {
        this._onClose.next(true);
        this.bsModalRef.hide();
    }

    public cancel(): void {
        this._onClose.next(false);
        this.bsModalRef.hide();
    }
}
