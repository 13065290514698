import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ApolloError } from "@apollo/client/core";
import { AuthService } from "@practica/auth/auth.service";
import { GraphQLService } from "@practica/graphql/graphql.service";
import { Login } from "@practica/graphql/mutations/__generated__/Login";
import { RavenWrapper } from "../../../RavenWrapper";
import { first } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "practica-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  disabled = false;
  hideErrors = false;
  networkError: string;

  // changing the form 'action' attribute makes
  // browsers prompt to remember login data
  action = "login";

  constructor(
    private fb: UntypedFormBuilder,
    private graphql: GraphQLService,
    private auth: AuthService,
    private raven: RavenWrapper,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: ["", Validators.compose([Validators.required])],
    });
  }

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }

  get showErrors() {
    const formInvalid =
      this.loginForm.invalid &&
      (this.loginForm.dirty || this.loginForm.touched);
    return !this.hideErrors && (formInvalid || this.networkError);
  }

  onSubmit() {
    this.hideErrors = false;
    this.disabled = true;
    this.networkError = null;

    this.graphql
      .login({
        email: this.email.value.trim(),
        password: this.password.value,
      })
      .pipe(first())
      .subscribe(
        (result) => {
          const data = <Login>result.data;
          if (data && data.login) {
            this.auth
              .login(data.login.token, this.route, data.login.me)
              .catch((reason) => {
                this.raven.captureException(reason);
              });
            this.action = "";
          } else {
            this.networkError = "Date de conectare incorecte";
            this.disabled = false;
          }
        },
        (err) => {
          this.networkError = "Eroare necunoscută; mai încearcă";
          if (err instanceof ApolloError) {
            if (!err.networkError && err.graphQLErrors) {
              this.networkError = err.message;
            }
          }
          this.disabled = false;
        }
      );
  }
}
