import {isPlatformServer} from '@angular/common';
import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';


export interface ImageSpec {
    url: string;
    width: number;
}

@Component({
    selector: 'practica-lazy-image',
    templateUrl: './lazy-image.component.html',
    styleUrls: ['./lazy-image.component.scss']
})
export class LazyImageComponent {
    @Input() sizes: string;
    @Input() alt: string;
    @Input() images: ImageSpec[];

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }

    get disabled(): boolean {
        // disable on the server side - we don't want server-rendered html to have src
        // attributes on images, because it will force the page to wait for them to load
        return !this.images || this.images.length === 0 || isPlatformServer(this.platformId);
    }

    get srcset(): string {
        if (this.disabled) {
            return null;
        }

        return this.images.map(img => `${img.url} ${Math.round(img.width)}w`).join(', ');
    }

    get src(): string {
        if (this.disabled) {
            return null;
        }

        return this.images[0].url;
    }
}
