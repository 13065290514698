import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '@practica/auth/auth.service';
import lottieWeb from 'lottie-web';
import {ThemeService} from '../../../theme.service';

@Component({
  selector: 'practica-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit, AfterViewInit {
    Hero1: string;
    Hero2: string;
    SubHero: string;
    facultyShortName: string;
    constructor(
        private authService: AuthService,
        private readonly _themeService: ThemeService,
        private readonly _route: ActivatedRoute,
        ) {
            this.setHeroText();
    }

    ngAfterViewInit(): void {
        lottieWeb.loadAnimation({
            container: document.getElementById('headerAnimation'),
            path: this._themeService.getHeaderAnimationPath(this._route),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: 'Demo Animation', });
    }

  ngOnInit(): void {
  }
    showButton(): boolean {
        return Boolean(!this.authService.getToken());
    }
    setHeroText(): void {
        this.Hero1 = this._themeService.getHeroText1(this._route);
        this.Hero2 = this._themeService.getHeroText2(this._route);
        this.SubHero = this._themeService.getSubHeroText(this._route);
        this.facultyShortName =  this._themeService.returnShortName(this._route) + '#companies';
    }
}
