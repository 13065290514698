import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {GraphQLService} from '@practica/graphql/graphql.service';
import { AccordTypeQuery } from "@practica/graphql/queries/__generated__/AccordTypeQuery";
import {Atestat, CaietDePractica, Convention} from '@practica/graphql/queries/__generated__/DocumentsQuery';
import {lastValueFrom} from 'rxjs';
import Scrollbar from "smooth-scrollbar";

@Component({
  selector: "practica-user-documents",
  templateUrl: "./user-documents.component.html",
  styleUrls: ["./user-documents.component.scss"],
})
export class UserDocumentsComponent implements OnInit, AfterViewInit {
  public accords: AccordTypeQuery[] = [];
  public accordsFiltered: AccordTypeQuery[] = [];
  public hasAccord: boolean;
  public selectedAccord: AccordTypeQuery;
  public showDocumentsSection: boolean = false;
  private convention_upload_url_base : string = "/upload/convention";
  public convention_upload_url: string;
  public atestat_upload_url: string = "/upload/atestat";
  public caiet_de_practica_upload_url: string = "/upload/caiet";
  public atestat: Atestat;
  public convention: Convention;
  public caietDePractica: CaietDePractica;
  @ViewChild("accordsContainer") accordsContainer;
  constructor(private route: ActivatedRoute, private graphql: GraphQLService) { }

  ngOnInit(): void {
    let data = this.route.snapshot.data.data
    this.accords = data.accords.accords;
    this.atestat = data.documents.atestat;
    this.convention = data.documents.convention;
    this.caietDePractica = data.documents.caietDePractica;
    this.accordsFiltered = this.accords;

    if (this.convention)
    {
      if (this.convention.acord) {
        this.selectedAccord = this.convention.acord;
        this.showDocumentsSection = true;
        this.hasAccord = true;
        this.initConventionUploader();
      }
    }
  }

  ngAfterViewInit(): void {
      Scrollbar.init(this.accordsContainer.nativeElement, {alwaysShowTracks: true});
  }

  public async onAccordOptionSelect(hasAccord: boolean): Promise<void> {
    this.hasAccord = hasAccord;

    if (this.hasAccord == false && !this.convention) {
      await this.markPhysicalConvention();
    }
  }

  public onAccordSearchChange(searchText: string): void {
    this.accordsFiltered = this.accords.filter(p => p.name.toLowerCase().includes(searchText.toLowerCase()));
  }

  public onAccordSelected(accord: AccordTypeQuery) : void {
    this.selectedAccord = accord;
    this.showDocumentsSection = true;
    this.initConventionUploader();
  }

  private async markPhysicalConvention(): Promise<void> {
    await lastValueFrom(this.graphql.markPhysicalConvention());
  }

  private initConventionUploader(): void
  {
    this.convention_upload_url = this.convention_upload_url_base + '/' + encodeURI(this.selectedAccord.id);
  }
}
