<div class="offers-container" id="offers-container" #offerscontainer>

    <div *ngFor="let internship of internships; let i = index">

        <button
                class="navigation-button"
                [ngClass]="selectedInternshipIndex == i ? 'active' : ''"
                (click)="handleSelection(i)"
        >
            <span class="internship-title">{{internship.title}}</span>
            <i class="gg-arrow-right ml-3 align-self-center" *ngIf="!hasApplicationForInternship(internship.id)"></i>
            <i class="gg-check ml-3 align-self-center" *ngIf="hasApplicationForInternship(internship.id)"></i>
        </button>
    </div>

</div>

