<main class="px-4 px-md-0">
    <div class="row my-5">
    <h3 class="section-title">Alege companiile la care vrei să aplici</h3>
    <p>Dacă te-ai hotărât să aplici la una sau mai multe dintre companiile partenere, selectează-ți preferințele din lista
        de mai jos.</p>

    <p>La finalul lunii datele de contact ale studenților interesați vor fi trimise către reprezentanții companiilor pentru
        selecție.</p>
    </div>

    <section class="row company-container py-5">
        <div class="col-lg-3 col-md-4 col-sm-2 col-6" *ngFor="let company of companies">
            <a [routerLink]="'/' + facultyShortName + '/user/company/' + company.slug" class="py-3 px-2">
                <div class="company-wrapper">
                    <practica-lazy-image
                            style="cursor: pointer;"
                            alt="{{company.name}} logo" [images]="company.logo"
                            sizes="(min-width: 1200px) 18vw, (min-width: 992px) 22vw, (min-width: 768px) 30vw, (min-width: 576px) 45vw, 90vw">
                    </practica-lazy-image>
                </div>
            </a>
        </div>
    </section>
</main>
