import { Injectable } from '@angular/core';
import * as Raven from 'raven-js';
import {RavenOptions} from 'raven-js';
import {environment} from '../environments/environment';

@Injectable()
export class RavenWrapper {
    private wrap(action: () => void): boolean {
        if (environment.production) {
            try {
                // NOTE: "production" is any build generated by "ng build --prod"
                //  - this includes dev.practica.ligaac.ro, or even local builds with "npm run build:ssr"
                action();
                return true;
            } catch (sentryErr) {
                console.group('sentry.io/raven error');
                console.error(sentryErr);
                console.groupEnd();
            }
        }

        return false;
    }

    public config(dsn: string, options?: RavenOptions): boolean {
        return this.wrap(() => Raven.config(dsn, options).install());
    }

    public captureException(ex: Error | ErrorEvent | string, options?: RavenOptions): boolean {
        const reported = this.wrap(() => Raven.captureException(ex, options));
        if (!reported) {
            console.error(ex);
        }
        return reported;
    }

    public setUserContext(user?: { [key: string]: string | number | boolean | null | void }): boolean {
        return this.wrap(() => Raven.setUserContext(user));
    }

    public setEnvironment(env: string): boolean {
        return this.wrap(() => Raven.setEnvironment(env));
    }
}
