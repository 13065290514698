<div class="py-5 header">
    <practica-home-header></practica-home-header>
</div>

<div class="py-5">
        <practica-cards ></practica-cards>
</div>

<div id="companies" class="scroll-target py-5">
    <practica-companies></practica-companies>
</div>
