import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CompanyContact, CompanyDetail, CompanyInfo, Internship} from '@practica/graphql/graphql.types';
import Scrollbar from 'smooth-scrollbar';

@Component({
  selector: 'practica-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {
  @Input() company: CompanyInfo;
  @Input() showCompanyDetails = false;
  @Input() internship: Internship | undefined;
  @Input() contacts: CompanyContact[];
  @Input() hasInternshipApplication = false;
  @Input() displayApplyActions = false;
  @Output() addOrRemoveApplicationEvent = new EventEmitter<string>();
  @ViewChild('offerContainer') set content(content: ElementRef) {
    if (content && content.nativeElement)
      Scrollbar.init(content.nativeElement, {alwaysShowTracks: true});
  };
  @ViewChild('companyContainer') set content2(content: ElementRef) {
    if (content && content.nativeElement)
      Scrollbar.init(content.nativeElement, {alwaysShowTracks: true});
  };

  constructor() { }

  ngOnInit(): void {

  }

  getContactEmails(): string {
    return this.contacts.map(e => e.email).join(', ');
  }

  getContactPhones(): string {
    return this.contacts.map(e => e.phone).join(', ');
  }

  hasContactEmails(): boolean {
    return this.contacts.length > 0 && this.contacts.filter(e => e.email && e.email != '').length > 0;
  }

  hasContactPhoneNumbers(): boolean {
    return this.contacts.length > 0 && this.contacts.filter(e => e.phone && e.phone != '').length > 0;
  }

  addOrRemoveApplication(id: string): void {
    this.addOrRemoveApplicationEvent.emit(id);
  }

}
