import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {Faq} from '@practica/graphql/graphql.types';

@Injectable()
export class FaqListResolve  {
    constructor(private graphql: GraphQLService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.graphql.resolve.faqs();
    }
}
