import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GraphQLService } from "@practica/graphql/graphql.service";
import { AddCompanyUserRequest } from "@practica/graphql/mutations/__generated__/AddCompanyUserRequest";
import { facultyresponsibleSetQuery_edges_node } from "@practica/graphql/queries/__generated__/FacultyTypeQuery";

@Component({
  selector: "practica-company-register",
  templateUrl: "./company-register.component.html",
  styleUrls: ["./company-register.component.scss"],
})
export class CompanyRegisterComponent implements OnInit {
  public registerForm: UntypedFormGroup;
  public faculties = undefined;
  public facultyResponsibles = [];
  public formErrors = [];
  private facultyResponsiblesDictionary: {
    [id: string]: facultyresponsibleSetQuery_edges_node;
  } = {};
  public selectedFaculties = [];
  private selectedFacultyResponsible = undefined;
  public backendErrors: string[] = [];
  constructor(
    private gqlService: GraphQLService,
    private route: ActivatedRoute
  ) {
    this.faculties = this.route.snapshot.data.data.faculties;

    // extract all responsibles from all faculties in a dictionary to prevent duplicates
    this.faculties.map((r) =>
      r.facultyresponsibleSet.edges.map(
        (p) => (this.facultyResponsiblesDictionary[p.node.id] = p.node)
      )
    );

    this.arrangeFacultyResponsibles();
  }

  async ngOnInit() {
    this.registerForm = new UntypedFormGroup({
      email: new UntypedFormControl(
        "",
        Validators.compose([Validators.email, Validators.required])
      ),
      firstName: new UntypedFormControl("", Validators.required),
      lastName: new UntypedFormControl("", Validators.required),
      password: new UntypedFormControl("", Validators.required),
      phone: new UntypedFormControl("", Validators.required),
      password2: new UntypedFormControl("", Validators.required),
      companyName: new UntypedFormControl("", Validators.required),
      observations: new UntypedFormControl(""),
    });
  }

  onFacultySelection(event) {
    this.selectedFaculties = event;
    this.facultyResponsiblesDictionary = {};
    this.selectedFaculties.map((r) =>
      r.facultyresponsibleSet.edges.map(
        (p) => (this.facultyResponsiblesDictionary[p.node.id] = p.node)
      )
    );
    this.arrangeFacultyResponsibles();
  }
  onFacultyResponsibleSelection(event) {
    this.selectedFacultyResponsible = event;
  }

  private arrangeFacultyResponsibles() {
    this.facultyResponsibles = [];
    for (let key in this.facultyResponsiblesDictionary) {
      let responsible = this.facultyResponsiblesDictionary[key];
      this.facultyResponsibles.push({
        id: responsible.id,
        name:
          responsible.user.firstName +
          " " +
          responsible.user.lastName +
          "(" +
          responsible.user.email +
          ")",
      });
    }
  }

  send(form: UntypedFormGroup) {
    this.formErrors = [];
    if (
      !form.valid ||
      this.selectedFaculties.length <= 0 ||
      this.selectedFacultyResponsible === undefined ||
      form.value.password != form.value.password2 || form.value.password.length < 8
        || form.value.phone.length < 10
    ) {
      if (this.selectedFacultyResponsible === undefined)
        this.formErrors.push("Selectează responsabilul de facultate!");
      if (this.selectedFaculties.length <= 0)
        this.formErrors.push(
          "Selectează cel puțin o facultate la care te adresezi!"
        );
      if (form.controls.email.errors)
        this.formErrors.push("Verifică email-ul!");
      if (form.controls.firstName.errors)
        this.formErrors.push("Verifică prenumele!");
      if (form.controls.lastName.errors)
        this.formErrors.push("Verifică numele!");
      if (form.controls.phone.errors || form.value.phone.length < 10)
        this.formErrors.push("Verifică numărul de telefon!");
      if (form.controls.password.errors)
        this.formErrors.push("Verifică parola!");
      if (form.controls.password2.errors)
        this.formErrors.push("Verifică confirmarea parolei!");
      if (form.controls.companyName.errors)
        this.formErrors.push("Verifică numele companiei!");
      if (form.value.password != form.value.password2)
        this.formErrors.push("Parolele nu potrivesc!");
      if (form.value.password.length < 8)
        this.formErrors.push("Parola trebuie să aibă cel puțin 8 caractere!");
    } else {
      let request: AddCompanyUserRequest = {
        firstName: form.value.firstName,
        lastName: form.value.lastName,
        phone: form.value.phone,
        email: form.value.email,
        password: form.value.password,
        faculties: this.selectedFaculties.map((f) => f.id),
        facultyResponsible: this.selectedFacultyResponsible.id,
        companyName: form.value.companyName,
        observations: form.value.observations,
      };

      this.gqlService.addCompanyUserRequest(request).subscribe((p) => {
        this.redirectToCompanyLogin();
      }, (e) => this.backendErrors.push(e) );
    }
  }

  redirectToCompanyLogin(): void {
    window.location.href = "https://" + window.location.host + "/admin";
  }
}
