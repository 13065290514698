import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import {GraphQLService} from "@practica/graphql/graphql.service";
import {AccordTypeQuery} from "@practica/graphql/queries/__generated__/AccordTypeQuery";


@Injectable()
export class UserDocumentsResolve  {
    constructor(private graphql: GraphQLService) {
    }

    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        let accords = await this.graphql.resolve.accords();
        let documents = await this.graphql.resolve.studentDocuments();

        return {accords, documents}
    }
}
