import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {UserProfile_companies_edges_node} from '@practica/graphql/queries/__generated__/UserProfile';
import {Subscription} from 'rxjs';
import {ThemeService} from "../../../theme.service";

@Component({
  selector: 'practica-user-companies',
  templateUrl: './user-companies.component.html',
  styleUrls: ['./user-companies.component.scss']
})
export class UserCompaniesComponent implements OnInit {
  private subscription: Subscription;
  companies: UserProfile_companies_edges_node[];
  facultyShortName: string;

  constructor(private route: ActivatedRoute, private graphql: GraphQLService, private themeService: ThemeService) { }

  ngOnInit() {
    const data = this.route.snapshot.data;
    if (data && data.data) {
      this.setCompanies(data.data);
    } else {
      this.subscription = this.graphql.profileCompanies().valueChanges.subscribe(result => {
        if (result.data) {
          this.setCompanies(result.data);
        }
      });
    }
    this.facultyShortName = this.themeService.returnShortName(this.route);
  }

  setCompanies(data): void {
    this.companies = data.companiesUserProfile.edges.map(e => e.node);
  }

}
