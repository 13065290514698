import {AfterViewInit, Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Internship} from '@practica/graphql/graphql.types';
import Scrollbar from 'smooth-scrollbar';

@Component({
  selector: 'practica-profile-internship-offers',
  templateUrl: './profile-internship-offers.component.html',
  styleUrls: ['./profile-internship-offers.component.scss']
})
export class ProfileInternshipOffersComponent implements OnInit, AfterViewInit {
  @Input() internships: Internship[];
  @Input() selectedInternshipIndex: number;
  @Input() handleSelection: (index: number) => void;
  @Input() applicationsIds: string[] = [];
  @ViewChild('offerscontainer') offersContainerRef;
  entry!: ViewContainerRef;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    Scrollbar.init(this.offersContainerRef.nativeElement, {alwaysShowTracks: true});
  }

  hasApplicationForInternship(id: string): boolean {
    return this.applicationsIds.includes(id);
  }

}
