import {Injectable} from '@angular/core';
import {FetchResult, WatchQueryOptions} from '@apollo/client/core';
import {CompanyInfo, FacultyType, Faq} from '@practica/graphql/graphql.types';
import {AddCompanyUserRequest} from '@practica/graphql/mutations/__generated__/AddCompanyUserRequest';
import {UpdateProfile, UpdateProfileVariables} from '@practica/graphql/mutations/__generated__/UpdateProfile';
import {Documents} from '@practica/graphql/queries/__generated__/DocumentsQuery';
import {FacultyTypeQuery} from '@practica/graphql/queries/__generated__/FacultyTypeQuery';
import {Apollo, QueryRef} from 'apollo-angular';

import {CompanyDetailQuery, CompanyDetailQuery_companyBySlug} from '@practica/graphql/queries/__generated__/CompanyDetailQuery';
import {CompanyListQuery} from '@practica/graphql/queries/__generated__/CompanyListQuery';
import {FaqQuery} from '@practica/graphql/queries/__generated__/FaqQuery';
import {ProfileCompanies, UserProfile} from '@practica/graphql/queries/__generated__/UserProfile';

import {Login, LoginVariables} from '@practica/graphql/mutations/__generated__/Login';
import {Register, RegisterVariables} from '@practica/graphql/mutations/__generated__/Register';
import {RequestPasswordReset, RequestPasswordResetVariables} from '@practica/graphql/mutations/__generated__/RequestPasswordReset';




import {DocumentNode} from 'graphql';
import {Observable} from 'rxjs';
import {cacheKeyCompanyNode} from './graphql.config';
import {AccordTypeQuery} from "@practica/graphql/queries/__generated__/AccordTypeQuery";

const faqQuery = require('graphql-tag/loader!app/practica/graphql/queries/FaqQuery.graphql');
const companiesQuery = require('graphql-tag/loader!app/practica/graphql/queries/CompanyListQuery.graphql');
const companyDetailQuery = require('graphql-tag/loader!app/practica/graphql/queries/CompanyDetailQuery.graphql');
const companyInfoFragment = require('graphql-tag/loader!app/practica/graphql/fragments/CompanyInfoFragment.graphql');
const profileQuery = require('graphql-tag/loader!app/practica/graphql/queries/UserProfileQuery.graphql');
const facultiesQuery = require('graphql-tag/loader!app/practica/graphql/queries/FacultiesQuery.graphql');
const accordsQuery = require('graphql-tag/loader!app/practica/graphql/queries/AccordsQuery.graphql');
const documentsQuery = require('graphql-tag/loader!app/practica/graphql/queries/DocumentsQuery.graphql');

const registerMutation = require('graphql-tag/loader!app/practica/graphql/mutations/Register.graphql');
const loginMutation = require('graphql-tag/loader!app/practica/graphql/mutations/Login.graphql');
const requestPasswordResetMutation = require('graphql-tag/loader!app/practica/graphql/mutations/RequestPasswordReset.graphql');
const addApplicationMutation = require('graphql-tag/loader!app/practica/graphql/mutations/AddApplication.graphql');
const removeApplicationMutation = require('graphql-tag/loader!app/practica/graphql/mutations/RemoveApplication.graphql');
const updateProfileMutation = require('graphql-tag/loader!app/practica/graphql/mutations/UpdateProfile.graphql');
const addCompanyUserMutation = require('graphql-tag/loader!app/practica/graphql/mutations/AddCompanyUser.graphql');
const markPhysicalConvention = require('graphql-tag/loader!app/practica/graphql/mutations/MarkPhysicalConvention.graphql');

class RelayEdge<T> {
    node: T;
}

class RelayConnection<T> {
    edges: RelayEdge<T>[];
}

class GraphQLCacheService {
    constructor(private apollo: Apollo) {
    }

    public companyBySlug(slug: string): CompanyInfo | null {
        return this.readFragment(companyInfoFragment, cacheKeyCompanyNode(slug));
    }

    private readFragment<T>(fragmentQuery: DocumentNode, id: string): T | null {
        return this.apollo.getClient().readFragment({
            id: id,
            fragment: fragmentQuery,
        });
    }
}

class GraphQLResolveService {
    constructor(private graphql: GraphQLService) {
    }

    public faqs(): Promise<Faq[]> {
        return this.resolveNodes(this.graphql.faqs(), qr => qr.faq);
    }

    public companies(hasInternships: boolean, facultyCode: string): Promise<CompanyInfo[]> {
        return this.resolveNodes(this.graphql.companies(hasInternships, facultyCode), qr => qr.companies);
    }

    public companyBySlug(slug: string, facultyCode: string): Promise<CompanyDetailQuery_companyBySlug> {
        return this.resolveRoot(this.graphql.companyBySlug(slug, facultyCode), qr => qr.companyBySlug);
    }

    public profile(): Promise<UserProfile> {
        return this.resolveRoot(this.graphql.profile(), qr => qr);
    }

    public studentDocuments(): Promise<Documents> {
        return this.resolveRoot(this.graphql.studentDocuments(), qr => qr);
    }

    public faculties(): Promise<FacultyType[]> {
        return this.resolveRoot(this.graphql.faculties(), qr => qr);
    }

    public accords(): Promise<AccordTypeQuery[]> {
        return this.resolveRoot(this.graphql.accords(), qr => qr);
    }

    private resolveNodes<QT, T>(relayQueryRef: QueryRef<QT>, connectionGetter: (query: QT) => RelayConnection<T>): Promise<T[]> {
        return new Promise<T[]>((resolve, reject) => {
            const sub = relayQueryRef.valueChanges.subscribe(result => {
                const data = result.data;
                const connection = connectionGetter(data);
                const nodes: T[] = connection.edges.map(e => e.node);
                resolve(nodes);
                sub.unsubscribe();
            }, (err: any) => {
                reject(err);
                sub.unsubscribe();
            });
        });
    }

    private resolveRoot<QT, T>(queryRef: QueryRef<QT>, memberGetter: (query: QT) => T): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            queryRef.valueChanges.subscribe(result => resolve(memberGetter(result.data)), (err: any) => reject(err));
        });
    }
}

@Injectable()
export class GraphQLService {
    private readonly _cachedService: GraphQLCacheService;
    private readonly _resolveService: GraphQLResolveService;

    constructor(private apollo: Apollo) {
        this._cachedService = new GraphQLCacheService(this.apollo);
        this._resolveService = new GraphQLResolveService(this);
    }

    public get cached() {
        return this._cachedService;
    }

    public get resolve() {
        return this._resolveService;
    }

    public faqs(): QueryRef<FaqQuery> {
        return this.watchQuery(faqQuery);
    }

    public companies(hasInternships: boolean, facultyCode: string): QueryRef<CompanyListQuery> {
        return this.watchQuery(companiesQuery, {hasInternships: hasInternships, facultyCode: facultyCode});
    }

    public companyBySlug(slug: string, facultyCode: string): QueryRef<CompanyDetailQuery> {
        return this.watchQuery(companyDetailQuery, {slug: slug, facultyCode: facultyCode});
    }

    public profile() {
        return this.watchQuery<UserProfile>(profileQuery, {}, 'network-only');
    }

    public profileCompanies() {
        return this.watchQuery<ProfileCompanies>(profileQuery, {}, 'network-only');
    }

    public login(variables: LoginVariables) {
        return this.mutate<Login>(loginMutation, variables);
    }

    public register(variables: RegisterVariables) {
        return this.mutate<Register>(registerMutation, variables);
    }

    public requestPasswordReset(variables: RequestPasswordResetVariables) {
        return this.mutate<RequestPasswordReset>(requestPasswordResetMutation, variables);
    }

    public updateProfile(variables: UpdateProfileVariables) {
        return this.mutate<UpdateProfile>(updateProfileMutation, variables);
    }

    public addApplication(internshipId: string) {
        return this.mutate<any>(addApplicationMutation, {input: internshipId});
    }

    public removeApplication(internshipId: string) {
        return this.mutate<any>(removeApplicationMutation, {input: internshipId});
    }

    public markPhysicalConvention() {
        return this.mutate<any>(markPhysicalConvention);
    }

    public studentDocuments() {
        return this.watchQuery<Documents>(documentsQuery);
    }

    public faculties() {
        return this.watchQuery<FacultyTypeQuery[]>(facultiesQuery);
    }

    public accords() {
        return this.watchQuery<AccordTypeQuery[]>(accordsQuery);
    }

    public addCompanyUserRequest(request: AddCompanyUserRequest) {
        return this.mutate<any>(addCompanyUserMutation, {request: request})
    }

    private mutate<T>(mutation: DocumentNode, variables: Record<string, any> = {}): Observable<FetchResult<T>> {
        return this.apollo.mutate({mutation: mutation, variables: variables});
    }

    private watchQuery<T>(query: DocumentNode, variables: Record<string, any> = {}, fetchPolicy: string = 'cache-first'): QueryRef<T> {
        return this.apollo.watchQuery(<WatchQueryOptions>{query: query, fetchPolicy: fetchPolicy, variables: variables});
    }
}
