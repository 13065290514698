<div class="user-modal-container">
    <header class="modal-header">
        <h4 class="modal-title pull-left">Ești sigur?</h4>
        <button type="button" class="close pull-right" aria-label="close" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </header>
    <main class="modal-body">
        <p>
            Ai ales să îți ștergi opțiunile din secțiunea <strong>"{{ sectionTitle }}"</strong>!<br/>
        </p>
    </main>
    <footer class="modal-footer">
        <button type="button" class="btn" (click)="confirm()">Șterge</button>
        <button type="button" class="btn btn-default" (click)="cancel()">Anulează</button>
    </footer>
</div>
