import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, NavigationError, Router} from '@angular/router';
import {Angulartics2GoogleAnalytics} from 'angulartics2';
import {ScrollService} from './scroll.service';
import {ThemeService} from './theme.service';

@Component({
    selector: 'practica-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {
    favIcon: HTMLLinkElement = document.querySelector('#icon');
    constructor(private analytics: Angulartics2GoogleAnalytics,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private titleService: Title,
                private scrollService: ScrollService,
                private readonly _themeService: ThemeService
    ) {
    }


    ngOnInit() {
        this.scrollService.install();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let route = this.activatedRoute;
                while (route.firstChild) {
                    route = route.firstChild;
                }

                if (route.outlet === 'primary') {
                    route.data.subscribe((data) => {
                        if ('title' in data) {
                            this.titleService.setTitle(data['title']);
                        }
                    });
                }
            } else if (event instanceof NavigationError) {
                console.error('navigation error');
                console.log(event);
            }
        });
        this.analytics.startTracking();
    }

    ngAfterContentChecked() {
        this.scrollService.restorePendingScroll();
    }
}
