<div class="container" *ngIf="assignedColors && faqs">
    <div class="row">
        <h3 class="section-title row py-3 px-md-0">Întrebări frecvente</h3>
        <p>
            Aici găsești răspunsurile la întrebările cele mai frecvente ale altor studenți.
            Dacă întrebarea ta nu e printre ele, trimite-ne un mail la <span><a href="mailto:practica@upt.ro"
                                                                                class="color-primary">practica&#64;upt.ro</a></span>
            și îți vom răspunde în cel mai scurt timp!
        </p>
        <main class="row py-5">
            <div class="p-2 col-md-6 col-12" *ngFor="let faq of faqs,let index = index">
                <div class="py-5 px-5 faq-item" [ngStyle]="{'background-color': assignedColors[index]}">
                    <h4 class="color-primary">{{faq.question}}</h4>
                    <p [innerHTML]="faq.answer | keepHtml"></p>
                </div>
            </div>
        </main>
    </div>
</div>
