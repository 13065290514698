import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'practica-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() public title;
  @Input() public description;
  @Input() public cardAnimation;

  constructor() {}


  ngOnInit(): void {
  }

}
