<section class="w-100">
    <h1 class="text-center section-title">Salut, alege portalul facultății tale pentru a continua</h1>
    <div class="d-flex justify-content-center flex-wrap mt-5">
        <practica-faculty-card
                [facultyName]="faculty.facultyName"
                [facultyImage]=" './assets/' + faculty.facultyShortName + '/favicon.ico'"
                [routerUrl]="'/' + faculty.facultyShortName"
                *ngFor="let faculty of faculties"></practica-faculty-card>
    </div>
</section>
