import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {GraphQLService} from '@practica/graphql/graphql.service';
import {CompanyDetail} from '@practica/graphql/graphql.types';
import {ThemeService} from "../../../theme.service";

@Injectable()
export class CompanyDetailResolve  {
    constructor(private graphql: GraphQLService, private themeService: ThemeService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const slug = route.params['slug'];
        const facultyCode = this.themeService.returnCachedShortName();
        return this.graphql.resolve.companyBySlug(slug, facultyCode);
    }
}
